import { withStyles, Theme } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

const LightTooltip = withStyles((theme: Theme) => ({
	arrow: {
		color: "#F7F7F7",
	},
	tooltip: {
		maxWidth: 195,
		padding: 10,
		backgroundColor: "#F7F7F7",
		color: "#333",
		boxShadow: "0 1px 2px #00000033",
		fontSize: 11
	},
}))(Tooltip);

export default LightTooltip;
