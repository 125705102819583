import axios from 'axios';
import OpenBankingPlatformAPIInterface from "./OpenBankingPlatformAPIInterface";
import DataCluster from "../openbankingplatform/models/DataCluster";
import Configuration from "../admin/models/Configuration";
import OpenBankingPlatformMockApi from "./OpenBankingPlatformMockApi";

export default class OpenBankingPlatformAPI implements OpenBankingPlatformAPIInterface {
    constructor(private apiUrl: URL, private accessToken: string) {
        this.getDataClusters = this.getDataClusters.bind(this);
        this.updateConfiguration = this.updateConfiguration.bind(this);
        this.createConfiguration = this.createConfiguration.bind(this);
        this.getPrincipalConfigurations = this.getPrincipalConfigurations.bind(this);
        this.getConfiguration = this.getConfiguration.bind(this);
    }

    private async doGetRequest<T>(path: string, params?: object): Promise<T> {
        const url = this.apiUrl.toString() + `/${path}`;
        const timeout = parseInt(process.env.REACT_APP_API_TIMEOUT ?? "15000");

        return axios
            .get<T>(url, {
                method: 'get',
                headers: {
                    Authorization: `Bearer ${this.accessToken}`
                },
                params: params,
                timeout: timeout,
            })
            .then(res => res.data);
    }

    public async getDataClusters(): Promise<Array<DataCluster>> {
        const path = `data-clusters-list`;
        return this.doGetRequest<Array<DataCluster>>(path, {
        });
    }

    // TODO using mock api to stub not yet implemented functions. This should be removed in production.
    private mockApi = new OpenBankingPlatformMockApi();

    public async getConfiguration(configurationId: number): Promise<Configuration>
    {
        return this.mockApi.getConfiguration(configurationId);
    }

    public async getPrincipalConfigurations(principalId: number): Promise<Array<Configuration>>
    {
        return this.mockApi.getPrincipalConfigurations(principalId);
    }

    public async createConfiguration(principalId: number, name: string, description: string): Promise<Configuration>
    {
        return this.mockApi.createConfiguration(principalId, name, description);
    }

    public async updateConfiguration(principalId: number, newConfiguration: Configuration): Promise<Configuration>
    {
        return this.mockApi.updateConfiguration(principalId, newConfiguration);
    }
}