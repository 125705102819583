import React from "react";
import Grid from '@material-ui/core/Grid';
import { makeStyles } from "@material-ui/core";

interface AccreditationBadgeProps {
    companyName: string;
    dataRecepientId: string;
    logoUrl?: string,
    displayStyle?: 1 | 2
}

const useStyles = makeStyles((theme) => ({
    style1: {
        background: theme.palette.info.main
    },
    style2: {
        background: "none",
        "&:before": {
            background: theme.palette.secondary.main
        }
    }
}));

const AccreditationBadge = (props: AccreditationBadgeProps) => {
    const style = useStyles();

    return <Grid container className={`accreditation-badge ${props.displayStyle ? "style-" + props.displayStyle : ""} ${props.displayStyle === 2 ? style.style2 : style.style1}`}>
        <Grid item lg={props.logoUrl ? 8 : 12} xs={12} className="align-self-center">
            <h5><strong>{props.companyName}</strong></h5>
            <p>Accredited Data Recipient: {props.dataRecepientId}</p>
        </Grid>
        {props.logoUrl && <Grid item lg={4} xs={12}>
            <img src={props.logoUrl} alt="" />
        </Grid>}
    </Grid>;
}

export default AccreditationBadge;
