import React, { useState } from "react";
import { Grid, Box, Button, InputBase, FormControl, Select, MenuItem, Hidden, makeStyles, darken } from "@material-ui/core";
import LinkButton from "../components/LinkButton";
import PrimaryBlock from "../components/PrimaryBlock";
import SecondaryBlock from "../components/SecondaryBlock";
import InstitutionListItem from "../components/InstitutionListItem";
import Header from "../components/Header";
import Footer from "../components/Footer";
import InstitutionListItemData from "../models/InstitutionListItemData";

interface DashboardState {
	data: Array<InstitutionListItemData>;
	keyword: string;
	category: string;
}

interface DashboardProps {
	connectDataHolderPath: string;
	dashboardPath: string;
}

const useStyles = makeStyles((theme) => ({
	searchBtn: {
		color: theme.palette.secondary.main,
		backgroundColor: theme.palette.info.main,

		"&:hover": {
			backgroundColor: darken(theme.palette.info.main, 0.1)
		}
	}
}));

const Dashboard = (props: DashboardProps) => {
	const [state, setState] = useState<DashboardState>({
		data: [
			{
				id: 100,
				institutionName: "Australia and New Zealand Banking Group Limited (ANZ)",
				logoImageUrl: "/images/data-holder-logos/anz-logo-round.png",
				status: "active",
				categories: ["home loan"],
				date: "28 August 2020",
				message: {
					type: "success",
					content: "You have successfully consented to sharing your ANZ data with us. You can see a summary of this sharing arrangement in here.",
					links: [{
						icon: "download",
						text: "Download confirmation PDF",
						url: "/sample-url"
					}]
				}
			},
			{
				id: 101,
				institutionName: "National Australia Bank Limited",
				logoImageUrl: "/images/data-holder-logos/nab-logo-round.png",
				status: "active",
				categories: ["home loan"],
				date: "28 August 2020"
			},
			{
				id: 102,
				institutionName: "Commonwealth Bank of Australia",
				logoImageUrl: "/images/data-holder-logos/commbank-logo-round.png",
				status: "active",
				categories: ["credit card", "transaction account"],
				date: "17 July 2020"
			},
			{
				id: 103,
				institutionName: "American Express",
				logoImageUrl: "/images/data-holder-logos/amex-logo-round.png",
				status: "pending",
				categories: [],
				date: "N/A"
			},
			{
				id: 104,
				institutionName: "ME Bank",
				logoImageUrl: "/images/data-holder-logos/me-logo-round.png",
				status: "inactive",
				categories: ["transaction account"],
				date: "14 March 2019"
			},
		],
		keyword: "",
		category: ""
	});

	const style = useStyles();

	return <>
		<Header />

		<PrimaryBlock>
			<h2>Data Sharing Dashboard</h2>
			<Grid container className={"dashboard-action-bar"}>
				<Grid item xs={12} md={6}>
					<LinkButton targetlocation={props.connectDataHolderPath} variant={"contained"} color={"secondary"}>Connect new institution</LinkButton>
				</Grid>
				<Grid item xs={12} md={6}>
					<Grid container className={"filters-bar"}>
						<Grid item xs={12} sm={6}>
							<InputBase
								id={"keyword-input"}
								placeholder={"Enter search"}
								inputProps={{ "aria-label": "Enter search" }}
								onChange={event => {
									setState({ ...state, keyword: event.target.value.toLowerCase() });
								}}
							/>
						</Grid>
						<Grid item xs={12} sm={3}>
							<FormControl variant="outlined">
								<Select
									id={"category-select"}
									value={state.category}
									onChange={event => {
										setState({ ...state, category: event.target.value as string });
									}}
								>
									<MenuItem value={""}>Filter by:</MenuItem>
									<MenuItem value={"home loan"}>Home loan</MenuItem>
									<MenuItem value={"credit card"}>Credit card</MenuItem>
									<MenuItem value={"transaction account"}>Transaction account</MenuItem>
								</Select>
							</FormControl>
						</Grid>
						<Grid item xs={12} sm={3}>
							<Button id={"search-btn"} className={style.searchBtn} variant={"contained"} color={"secondary"}>Search</Button>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<div className={"institution-list"}>
				<Hidden mdDown>
					<Grid container className={"table-head"}>
						<Grid item xs={1}></Grid>
						<Grid item xs={3}>Name</Grid>
						<Grid item xs={2}>Status</Grid>
						<Grid item xs={2}>Categories</Grid>
						<Grid item xs={2}>Consent granted date</Grid>
						<Grid item xs={2}></Grid>
					</Grid>
				</Hidden>
				{state.data && (
					state.data.filter(item => {
						return (item.institutionName.toLowerCase().includes(state.keyword) || state.keyword === "")
							&& (item.categories.includes(state.category) || state.category === "")
					}).map((item, i) => <InstitutionListItem consentDetailsPath={props.dashboardPath + "/consent-details"} key={i} {...item} />)
				)}
			</div>
			<div className={"pager"}>
				<strong>1-5</strong> of 5
			</div>
		</PrimaryBlock>

		<SecondaryBlock background-info>
			<Box textAlign={"left"}>
				Please read our <a href="/privacy-policy" target="_blank">Consumer Data Right policy</a> for some information on how Money Simple handles your data.
			</Box>
		</SecondaryBlock>

		<Footer />
	</>;
}

export default Dashboard;