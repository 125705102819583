import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import LinkButton from "../components/LinkButton";
import PrimaryBlock from "../components/PrimaryBlock";
import HighlightBlock from "../components/HighlightBlock";
import SwitchWithLabel from "../components/SwitchWithLabel";
import { Box, Container, Dialog, DialogContent, Button, DialogActions, Grid, Link } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import ButtonBlock from "../components/ButtonBlock";
import Header from "../components/Header";
import Footer from "../components/Footer";
import ModalPopUpData from "../models/ModalPopUpData";
import CloseIcon from '@material-ui/icons/Close';

interface previousDataDeleteConfirmationProps {
    onCancel?: () => void,
    next: string,
    prev: string,
    onDeleteOptionChange: (newOption: boolean) => void,
    currentDeleteOption: boolean | undefined
}

const PreviousDataDeleteConfirmation = (props: previousDataDeleteConfirmationProps) => {
    window.scrollTo(0,0);

    var newDeleteOption = false;
    if(props.currentDeleteOption){
        newDeleteOption = props.currentDeleteOption;
    }

    const [open, setOpen] = React.useState(false);
    const [opendeidentifypopup, setOpenGeneralPopUp] = React.useState(false);
    const [currentPopupModalData, setCurrentPopupModalData] = React.useState<ModalPopUpData>();
    const [deleteDataOption, setDeleteDataOption] = useState(newDeleteOption);
    const history = useHistory();

    const modalPopupsData = [
        {
            popUpHeading: "De-identification of data",
            popUpGeneralText: [],
            popupBoxContents:[],
            popupBlockContents:[
                {
                    heading: "What does it mean to de-identify data?",
                    content: [
                        {
                            text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin elit felis, hendrerit eu mauris nec, iaculis placerat nisi. Nunc id tellus orci. Integer faucibus mauris sit amet ipsum accumsan consequat."
                        }
                    ]
                },
                {
                    heading: "How will we de-identify your data?",
                    content: [
                        {
                            text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin elit felis, hendrerit eu mauris nec, iaculis placerat nisi. Nunc id tellus orci. Integer faucibus mauris sit amet ipsum accumsan consequat."
                        }
                    ]
                },
                {
                    heading: "How will we use your de-identified data?",
                    content: [
                        {
                            text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin elit felis, hendrerit eu mauris nec, iaculis placerat nisi. Nunc id tellus orci. Integer faucibus mauris sit amet ipsum accumsan consequat."
                        }
                    ]
                },
                {
                    heading: "Want more information?",
                    content: [
                        {
                            text: "Refer to our Consumer Data Right policy for more information on data de-identification."
                        }
                    ]
                }
            ]    
        },
        {
            popUpHeading: "Data deletion",
            popUpGeneralText: [],
            popupBoxContents:[],
            popupBlockContents:[
                {
                    heading: "What does it mean to delete data?",
                    content: [
                        {
                            text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin elit felis, hendrerit eu mauris nec, iaculis placerat nisi. Nunc id tellus orci. Integer faucibus mauris sit amet ipsum accumsan consequat. "
                        }
                    ]
                },
                {
                    heading: "When will you delete my data?",
                    content: [
                        {
                            text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin elit felis, hendrerit eu mauris nec, iaculis placerat nisi. Nunc id tellus orci. Integer faucibus mauris sit amet ipsum accumsan consequat. "
                        }
                    ]
                },
                {
                    heading: "How will you delete my data?",
                    content: [
                        {
                            text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin elit felis, hendrerit eu mauris nec, iaculis placerat nisi. Nunc id tellus orci. Integer faucibus mauris sit amet ipsum accumsan consequat. "
                        }
                    ]
                },
                {
                    heading: "What about supporting third parties?",
                    content: [
                        {
                            text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin elit felis, hendrerit eu mauris nec, iaculis placerat nisi. Nunc id tellus orci. Integer faucibus mauris sit amet ipsum accumsan consequat. "
                        }
                    ]
                },
                {
                    heading: "Want more information?",
                    content: [
                        {
                            text: "Refer to our Consumer Data Right policy for more information on data de-identification. "
                        }
                    ]
                }
            ],
        }
    ];
    
    const openPopup = () => {
        setOpen(true);
    };

    const closePopup = () => {
        setOpen(false);
    };

    const openGeneralPopup = (modalPopUpData: ModalPopUpData) => {
        setOpenGeneralPopUp(true);
        setCurrentPopupModalData(modalPopUpData);
    };

    const closeDeidentifyPopup = () => {
        setOpenGeneralPopUp(false);
    };

    const cancelProcess = () => {
        props.onCancel && props.onCancel();
    };

    const deIdentifyPopup = () => {
		const identifyPopUpDatas = modalPopupsData[0];
        openGeneralPopup(identifyPopUpDatas);
    }
    
    const deletePopup = () => {
		const deletePopUpDatas = modalPopupsData[1];
        openGeneralPopup(deletePopUpDatas);
	}

    const popUpgeneralTexts = currentPopupModalData?.popUpGeneralText.map((item) =>
            <p>{item.content}</p>
    );
    
    const popUpBoxTexts = currentPopupModalData?.popupBoxContents.map((item) =>
        <>
            <h6>{item.heading}</h6>
            <p>{item.content}</p>
        </>
    );

    const popUpBlockTexts = currentPopupModalData?.popupBlockContents.map(item =>{
        return (
        <>
            <h6>{item.heading}</h6>
            <br />
            {
                item.content?.map(word =>{
                    return (
                        <p>{word.text}</p>
                    )
                })
            }
            <br />
        </>
        )
    });

    const handleChange = (currentOption: boolean) => {
        setDeleteDataOption(currentOption);
        if(props.onDeleteOptionChange){
            props.onDeleteOptionChange(currentOption);
        }  
    }

    const handleGoBack = () => {
        history.push(props.prev);
    }


    return <>
        <Header />

        <PrimaryBlock>
            <Container maxWidth={"sm"} className={"p-0"}>
                <Grid item xs={12}>
					<Link color={"secondary"} className={"go-back-link"} href="/#" onClick={(e : any) => {e.preventDefault(); handleGoBack();}}><FontAwesomeIcon size={"sm"} icon={faArrowLeft} />Back</Link>
				</Grid>

                <h3 className = {"margin-top-on-data-delete-confirm"}>We need to stop collecting and using your previously shared data before you continue.</h3>
                <h6>How would you like us to handle your previously shared data?</h6>
                <p>We de-identify your data when we no longer need it.</p>
                <p><a href="/#" className={"popup-link"} onClick={(e) => {e.preventDefault(); deIdentifyPopup();}}>How and why we de-identify your data</a></p>
                <HighlightBlock className="additional-use-choices">
                    <div className={"item"}>
                        <label>
                            <SwitchWithLabel 
                                checked={deleteDataOption} 
                                onChange={value => { handleChange(value)}}
                            >
                                <h6><strong>Delete my data instead</strong></h6>
                            </SwitchWithLabel>
                        </label>
                    </div>
                    <p><a href="/#" className={"popup-link"} onClick={(e) => {e.preventDefault(); deletePopup();}}>See how we delete your data</a></p>
                </HighlightBlock>
                
                <ButtonBlock>
                    <Button onClick={openPopup} variant={"outlined"} color={"secondary"}>Cancel</Button>
                    <LinkButton targetlocation={props.next} variant={"contained"} color={"secondary"}>I understand</LinkButton>
                </ButtonBlock>
                <Box className={"text-center"} mt={7}>
                    <img src="/images/hero-image-3.png" alt="" />
                </Box>
            </Container>
        </PrimaryBlock>

        {/* Cancellation popup */}
        <Dialog
            maxWidth={"sm"}
            open={open}
            onClose={closePopup}
        >
            <DialogContent>
                <h2>Cancel process?</h2>
                <p>By not sharing your bank data, we will not be able to automatically collect this information. However, you can still provide this information manually when setting up Money Simple.</p>
                <p>Are you sure you would like to cancel this process?</p>
            </DialogContent>
            <DialogActions>
                <Button onClick={cancelProcess} variant={"contained"} color={"secondary"}>Yes</Button>
                <Button onClick={closePopup} variant={"outlined"} color={"secondary"}>No</Button>
            </DialogActions>
        </Dialog>

        <Dialog
            id={"popup-3"}
            maxWidth={"sm"}
            open={opendeidentifypopup}
        >
            <CloseIcon 
               onClick={closeDeidentifyPopup}
               className="close-popup-icon" 
            />
            <DialogContent>
                <h2>{currentPopupModalData?.popUpHeading}</h2>

                {popUpgeneralTexts}

                {(popUpBoxTexts?.length)?
                    <Box my={3} p={3} className={"background-grey"}>
                        {popUpBoxTexts}
                    </Box>
                    : ""
                }
                {popUpBlockTexts}
                <a href="/privacy-policy" target="_blank">View Consumer Data Right policy</a>
            </DialogContent>
        </Dialog>

        <Footer />
    </>;
}

export default PreviousDataDeleteConfirmation;
