import React, { PropsWithChildren } from "react";
import Switch, { SwitchProps } from "./Switch";

interface SwitchWithLabelProps extends SwitchProps { };

const SwitchWithLabel = (props: PropsWithChildren<SwitchWithLabelProps>) => {
    return <Switch {...props}><div>{props.children}</div></Switch>
}

export default SwitchWithLabel;
