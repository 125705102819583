import React from "react";
import { TextField, TextareaAutosize } from "@material-ui/core";

interface EditableTextProps {
	textArea?: boolean;
	editMode?: boolean;
	name?: string;
	id?: string;
	rowsMin?: Number;
	rowsMax?: Number;
	className?: string;
	value?: string;
	placeholder?: string;
	wrapper?: "p" | "div";
	eventHandlers?: object;
}

const EditableText = ({
	textArea = false,
	name = "",
	id = "",
	rowsMin = 5,
	rowsMax = 10,
	className = "",
	value = "",
	placeholder = "",
	wrapper = "p",
	editMode = false,
	eventHandlers
}: EditableTextProps) => {
	const wrap = (text: string) => {
		if (wrapper === "div") return <div>{text}</div>;
		if (wrapper === "p") return <p>{text}</p>;
		return <>{text}</>;
	};
	return editMode
		? (textArea
			? <TextareaAutosize
				className={`w-100 ${className}`}
				{...{ name, id, rowsMin, rowsMax, value, placeholder } as object}
				{...eventHandlers}
			/>
			: <TextField
				variant={"outlined"}
				className={`w-100 ${className}`}
				{...{ name, id, value, placeholder } as object}
				{...eventHandlers}
			/>
		)
		: wrap(value);
}

export default EditableText;
