import React, {useState} from "react";
import {Box, TextField, Grid, TextareaAutosize, Hidden, Button, Container} from "@material-ui/core";
import InfoIcon from '@material-ui/icons/Info';
import ButtonBlock from "../../components/ButtonBlock";
import LinkButton from "../components/LinkButton";
import LightTooltip from "../components/LightTooltip";

interface CreateConfigurationProps
{
    onCreateConfiguration?: (name: string, description: string) => void,
    basePath: string
}

interface CreateConfigurationState
{
    name: string,
    description: string,
}

const CreateConfiguration = (props: CreateConfigurationProps) => {
    const [state, setState] = useState<CreateConfigurationState>({
        name: "",
        description: "",
    })

    const handleSave = () => {
        props.onCreateConfiguration && props.onCreateConfiguration(state.name, state.description);
    };

    const handleChange = (newState: CreateConfigurationState) => {
        setState(newState);
    }

    return <Container>
        <h2><strong>New Configuration</strong></h2>
        <h5 className="text-large">Create a new configuration</h5>

        <Box mt={6}>
            <form className={"admin-form new-configuration-form"} autoComplete={"off"}>
                <Grid container className={"form-group"}>
                    <Grid item xs={12} sm={4} md={3} className={"align-self-center"}>
                        <label>Name of configuration (required) <LightTooltip title="Name of the administration configuration"
                                                                              placement="right-start" arrow><InfoIcon
                            color={"secondary"}/></LightTooltip></label>
                    </Grid>
                    <Grid item xs={12} sm={7} md={5}>
                        <TextField required variant="outlined" id="configuration-name" name="configuration-name"
                                   className="w-100" value={state.name} onChange={(event) => handleChange({
                            ...state,
                            name: event.target.value,
                        })}/>
                    </Grid>
                </Grid>
                <Grid container className={"form-group"}>
                    <Grid item xs={12} sm={4} md={3} className={"align-self-center"}>
                        <label>Description (optional) <LightTooltip title="Description of the configuration"
                                                                    placement="right-start" arrow><InfoIcon
                            color={"secondary"}/></LightTooltip></label>
                    </Grid>
                    <Grid item xs={12} sm={7} md={5}>
                        <TextareaAutosize rowsMin={5} rowsMax={5} id="description" name="description"
                                          className="w-100" value={state.description}
                                          onChange={(event) => handleChange({
                                              ...state,
                                              description: event.target.value,
                                          })}/>
                    </Grid>
                </Grid>
                <Grid container>
                    <Hidden xsDown><Grid item xs={12} sm={4} md={3}></Grid></Hidden>
                    <Grid item xs={12} sm={7} md={5}>
                        <ButtonBlock className={"text-align-right"}>
                            <LinkButton to={props.basePath} variant={"outlined"} color={"secondary"}>Cancel</LinkButton>
                            <Button onClick={() => handleSave()} variant={"contained"} color={"secondary"}
                                    disabled={state.name.length === 0}>Save</Button>
                        </ButtonBlock>
                    </Grid>
                </Grid>
            </form>
        </Box>
    </Container>;
}

export default CreateConfiguration;