import React, { PropsWithChildren } from "react";
import { Container, makeStyles } from "@material-ui/core";
import ButtonBlock from "./ButtonBlock";

interface SecondaryBlockProps {
    header?: JSX.Element | string;
    ctaButtons?: Array<JSX.Element>;
    displayItemsConnected?: boolean;
}

const useStyles = makeStyles((theme) => ({
    secondaryBlock: {
        background: theme.palette.info.main
    }
}));

const SecondaryBlock = (props: PropsWithChildren<SecondaryBlockProps>) => {
    const style = useStyles();

    return <div className={`secondary-block ${style.secondaryBlock}`}>
        <Container>
            {props.header && <h2>{props.header}</h2>}
            {props.children &&
                <div className={"content"}>
                    {props.children}
                </div>
            }
            {props.ctaButtons &&
                <div className={"cta"}>
                    <ButtonBlock>{props.ctaButtons.map((button, i) => <div key={i}>{button}</div>)}</ButtonBlock>
                </div>
            }
        </Container>
    </div>

}

export default SecondaryBlock;