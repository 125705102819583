import React, { PropsWithChildren } from "react";

interface SecondaryBlockItemProps {
    image: string;
    className?: string;
}

const Card = (props: PropsWithChildren<SecondaryBlockItemProps>) => {
    const className = "card-item"
        + ((props.className) ? " " + props.className : "");

    return <div className={className}>
        <img src={props.image} alt={""} />
        <div className="card-content">{props.children}</div>
    </div>
}
export default Card;