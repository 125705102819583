import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import DataHolder from "../models/DataHolder";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faSearch } from "@fortawesome/free-solid-svg-icons";
import { Grid, Paper, InputBase, makeStyles } from "@material-ui/core";
import PrimaryBlock from "../components/PrimaryBlock";
import Header from "../components/Header";
import Footer from "../components/Footer";

interface DataHolderSelectionProps {
    onSelectionChange: (newSelection: DataHolder) => void;
    haveAuthorisationWith?: Map<string, boolean>;
    next: string;
}

interface DataHolderSelectionState {
    filter?: string;
}

const useStyles = makeStyles((theme) => ({
    authorised: {
        borderColor: theme.palette.secondary.main
    }
}));

const DataHolderSelection = (props: DataHolderSelectionProps) => {
    const [state, setState] = useState<DataHolderSelectionState>({});
    const history = useHistory();

    const dataHolders: Array<DataHolder> = [
        {
            id: "anz",
            title: "Australia and New Zealand Banking Group Limited (ANZ)",
            logo: "/images/data-holder-logos/anz-logo.png",
        },
        {
            id: "amex",
            title: "American Express",
            logo: "/images/data-holder-logos/amex-logo.png",
        },
        {
            id: "bankwest",
            title: "Bankwest",
            logo: "/images/data-holder-logos/bankwest-logo.png",
        },
        {
            id: "cba",
            title: "Commonwealth Bank of Australia",
            logo: "/images/data-holder-logos/cba-logo.png",
        },
        {
            id: "hbl",
            title: "Heritage Bank Limited",
            logo: "/images/data-holder-logos/heritage-logo.png",
        },
        {
            id: "mbl",
            title: "Macquarie Bank Limited",
            logo: "/images/data-holder-logos/macquarie-logo.png",
        },
        {
            id: "meb",
            title: "Members Equity Bank Limited",
            logo: "/images/data-holder-logos/me-logo.png",
        },
        {
            id: "nab",
            title: "National Australia Bank Limited",
            logo: "/images/data-holder-logos/nab-logo.png",
        },
    ];

    const handleSelection = (selection: DataHolder) => {
        if (props.onSelectionChange) {
            props.onSelectionChange(selection);
        }
        history.push(props.next);
        // <Link to={}>
        //     <img src={banks} alt={""}/>
        // </Link>
    }

    const applyFilter = (newFilter: string) => {
        if (newFilter.length >= 3) {
            setState({
                ...state,
                filter: newFilter,
            })
        } else {
            setState({
                ...state,
                filter: undefined,
            })
        }
    }

    const style = useStyles();

    return <div className={"data-holder-selection"}>
        <Header />

        <PrimaryBlock>
            <h2>Choose your bank or institution</h2>

            <p>Search for your banking provider using the search bar below, or select from the list.</p>

            <Paper className="search-box">
                <InputBase
                    placeholder="Start typing your bank name"
                    inputProps={{ "aria-label": "Start typing your bank name" }}
                    onChange={(event) => applyFilter(event.target.value)}
                />
                <FontAwesomeIcon icon={faSearch} className="v-center" />
            </Paper>

            <Grid container spacing={2} className={"data-holder-search-result"}>
                {dataHolders.map(dataHolder => {
                    if (state.filter) {
                        if (!dataHolder.title.toLowerCase().includes(state.filter.toLowerCase())) {
                            return <></>;
                        }
                    }
                    const isAuthorised = props.haveAuthorisationWith && props.haveAuthorisationWith.has(dataHolder.id) && props.haveAuthorisationWith.get(dataHolder.id);
                    return <Grid item lg={3} sm={6} xs={12}>
                        <div className={`data-holder ${isAuthorised ? `authorised ${style.authorised}` : ""}`} key={dataHolder.id} onClick={() => handleSelection(dataHolder)}>
                            <img src={dataHolder.logo} alt={"logo"} />
                            <p>{dataHolder.title}</p>
                            {isAuthorised && <FontAwesomeIcon icon={faCheckCircle} />}
                        </div>
                    </Grid>
                })}
            </Grid>
        </PrimaryBlock>

        <Footer />
    </div>
}

export default DataHolderSelection;
