import React, {useEffect, useState} from "react";
import {Box, Grid, Hidden, FormControl, Checkbox, FormControlLabel, Button, Container} from "@material-ui/core";
import InfoIcon from '@material-ui/icons/Info';
import Configuration from "../models/Configuration";
import SwitchWithLabel from "../../components/SwitchWithLabel";
import Consent from "../../models/Consent";
import EditableText from "./EditableText";
import LightTooltip from "./LightTooltip";
import DataClusterOptionSet from "../../models/DataClusterOptionSet";
import ButtonBlock from "../../components/ButtonBlock";

interface EditConsentSettingsProps {
    configuration: Configuration,
    mode: "edit" | "view",
    onConfigurationUpdate?: (newConfiguration: Configuration) => void,
    onCancel?: () => void,
}

interface ConsentSettingsState
{
    consentCopy: Consent;
}

const ConfigurationConsentSettings = (props: EditConsentSettingsProps) => {
    const bgClass = props.mode === "edit" ? "background-grey" : "";

    const [state, setState] = useState<ConsentSettingsState>({
        consentCopy: { ...props.configuration.consent },
    });

    useEffect(() => {
        setState((state) => ({
            ...state,
            consentCopy: { ...props.configuration.consent }
        }))
    }, [props.mode, props.configuration.consent]);


    const setConsentCopy = (newConsent: Consent) => {
        setState({
            ...state,
            consentCopy: newConsent,
        });
    }

    const setDataCluster = (updatedDataClusterWithOptions: DataClusterOptionSet) => {
        const newDataClustersWithOptions = state.consentCopy.dataClustersWithOptions.map((dataClusterWithOptions) => {
            if (dataClusterWithOptions.clusterId === updatedDataClusterWithOptions.clusterId) {
                return updatedDataClusterWithOptions;
            } else {
                return dataClusterWithOptions;
            }
        })

        setConsentCopy({
            ...state.consentCopy,
            dataClustersWithOptions: newDataClustersWithOptions,
        })
    }

    const handleSave = () => {
        const newConfiguration: Configuration = {
            ...props.configuration,
            consent: state.consentCopy,
        }

        props.onConfigurationUpdate && props.onConfigurationUpdate(newConfiguration);
    };

    const handleCancel = () => {
        props.onCancel && props.onCancel();
    }
    const isEditMode = (props.mode === "edit");

    const dataClusters =
        <Grid item xs={12} sm={7} md={5}>
            {state.consentCopy.dataClustersWithOptions.map((dataClusterWithOptions, index) => {
                    const detailsBox = (dataClusterWithOptions.purposeInformation)
                        ? <>
                            <Box className={`details-box ${bgClass}`}>
                                <p>
                                    <FormControlLabel
                                        className = {"disabled-checkbox-label-color"}
                                        control={<Checkbox 
                                            name={dataClusterWithOptions.authorisationScopeId}
                                            checked={dataClusterWithOptions.isMandatory} 
                                            disabled={!isEditMode}
                                            className = {"disabled-checkbox-color"}
                                            onChange={
                                            (event, checked) => {
                                                setDataCluster({
                                                    ...dataClusterWithOptions,
                                                    isMandatory: checked,
                                                })
                                            }
                                        }/>}
                                        label="Is mandatory"
                                    />
                                </p>

                                <label>"Why we need it" text<LightTooltip
                                    title="This text will be displayed to the customer" placement="right-start"
                                    arrow><InfoIcon color={"secondary"}/></LightTooltip></label>
                                <EditableText
                                    editMode={props.mode === "edit"}
                                    name={"usageExplanatoryText"}
                                    value={dataClusterWithOptions.purposeInformation}
                                    eventHandlers={{
                                        onChange: (e: any) => {
                                            setDataCluster({...dataClusterWithOptions, purposeInformation: e.target.value})
                                        }
                                    }}
                                />
                                <label>List of data fields</label>
                                <ul>
                                    {dataClusterWithOptions.clusterPermissionNames.map((permissionName, index) =>
                                        <li key={index}>{permissionName}</li>
                                    )}
                                </ul>
                            </Box>
                        </>
                        : ""
                    ;

                    return <div className={"form-group"}>
                        <FormControlLabel
                            className = {"disabled-checkbox-label-color"}
                            control={<Checkbox 
                                name={dataClusterWithOptions.authorisationScopeId} 
                                checked={dataClusterWithOptions.isEnabled}
                                className = {"disabled-checkbox-color"}
                                color = {"primary"}
                                disabled={!isEditMode}
                                               onChange={
                                                   (event, checked) => {
                                                       setDataCluster({
                                                           ...dataClusterWithOptions,
                                                           isEnabled: checked,
                                                       })
                                                   }
                                               }/>}
                            label={dataClusterWithOptions.clusterName}
                        />
                        {detailsBox}
                    </div>
                }
            )}
        </Grid>;

    const consent = state.consentCopy;
    const form =
        <form className={"admin-form pre-consent-form mt-50"} autoComplete={"off"}>
            <div>
                <Box mb={2}>
                    <h4>Data Request</h4>
                </Box>
                <Grid container className={"mt-30"}>
                    <Grid item xs={12} sm={4} md={3}>
                        <label>Active consent duration explanatory text <LightTooltip title="Active consent duration explanatory text for data request"
                                                                                      placement="right-start"
                                                                                      arrow><InfoIcon
                            color={"secondary"}/></LightTooltip></label>

                    </Grid>
                    <Grid item xs={12} sm={7} md={5}>
                        <EditableText
                            textArea
                            editMode={props.mode === "edit"}
                            name={"activeConsentDurationExplanatoryText"}
                            value={consent.activeConsentDurationText}
                            eventHandlers={{
                                onChange: (e: any) => {
                                    setConsentCopy({...consent, activeConsentDurationText: e.target.value})
                                }
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container className={"mt-50"}>
                    <Grid item xs={12} sm={4} md={3}>
                        <label>Data cluster &amp; permission text <LightTooltip title="Whether to enable Data cluster &amp; permission text in data request"
                                                                                placement="right-start" arrow><InfoIcon
                            color={"secondary"}/></LightTooltip></label>
                    </Grid>
                    <Grid item xs={12} sm={7} md={5}>
                        <FormControl>
                            <SwitchWithLabel
                                checked={consent.inclDataClusterPermissionText}
                                disabled={!isEditMode}
                                onChange={value => {
                                    setConsentCopy({...consent, inclDataClusterPermissionText: value})
                                }}>
                                <h5>Include</h5>
                            </SwitchWithLabel>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container className={"mt-30"}>
                    <Grid item xs={12} sm={4} md={3}>
                        <label>Data types to collect &amp; usage explanatory text <LightTooltip
                            title="Data types to collect &amp; usage explanatory text for data request" placement="right-start" arrow><InfoIcon
                            color={"secondary"}/></LightTooltip></label>
                    </Grid>
                    {dataClusters}
                </Grid>
                <Grid container className={"mt-30"}>
                    <Grid item xs={12} sm={4} md={3}>
                        <label>Additional uses of data <LightTooltip title="Data types to collect &amp; usage explanatory text for data request"
                                                                     placement="right-start" arrow><InfoIcon
                            color={"secondary"}/></LightTooltip></label>
                    </Grid>
                    <Grid item xs={12} sm={7} md={5}>
                        <FormControl>
                            <SwitchWithLabel 
                                checked={consent.includeAdditionalUsesOfdata}
                                disabled={!isEditMode}
                                onChange={value => {
                                    setConsentCopy({...consent, includeAdditionalUsesOfdata: value})
                                }}
                            >
                                <h5>Include</h5>
                            </SwitchWithLabel>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container className={"mt-30"}>
                    <Grid item xs={12} sm={4} md={3}>
                        <label>Frequency of data collection text <LightTooltip title="Frequency of data collection text for data request"
                                                                               placement="right-start" arrow><InfoIcon
                            color={"secondary"}/></LightTooltip></label>
                    </Grid>
                    <Grid item xs={12} sm={7} md={5}>
                        <FormControl>
                            <SwitchWithLabel 
                                checked={consent.includeFrequencyOfDataCollectionText}
                                disabled={!isEditMode}
                                onChange={value => {
                                    setConsentCopy({...consent, includeFrequencyOfDataCollectionText: value})
                                }}
                            >
                                <h5>Include</h5>
                            </SwitchWithLabel>
                        </FormControl>
                        <Box className={`details-box ${bgClass}`}>
                            <Box>
                                <label>Main Heading <LightTooltip title="Main heading for data request"
                                                                  placement="right-start" arrow><InfoIcon
                                    color={"secondary"}/></LightTooltip></label>
                                <EditableText
                                    editMode={props.mode === "edit"}
                                    name={"fodcHeading"}
                                    value={consent.frequencyOfDataCollectionTextMainHeading}
                                    eventHandlers={{
                                        onChange: (e: any) => {
                                            setConsentCopy({...consent, frequencyOfDataCollectionTextMainHeading: e.target.value})
                                        }
                                    }}
                                />
                            </Box>
                            <Box mt={4}>
                                <label>Main Text <LightTooltip title="Main text for data request"
                                                               placement="right-start" arrow><InfoIcon
                                    color={"secondary"}/></LightTooltip></label>
                                <EditableText
                                    textArea
                                    editMode={props.mode === "edit"}
                                    name={"fodcText"}
                                    value={consent.frequencyOfDataCollectionTextMainText}
                                    eventHandlers={{
                                        onChange: (e: any) => {
                                            setConsentCopy({...consent, frequencyOfDataCollectionTextMainText: e.target.value})
                                        }
                                    }}
                                />
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
                <Grid container className={"mt-30"}>
                    <Grid item xs={12} sm={4} md={3}>
                        <label>Data deletion standard text <LightTooltip title="Whether to include data deletion standard text"
                                                                         placement="right-start" arrow><InfoIcon
                            color={"secondary"}/></LightTooltip></label>
                    </Grid>
                    <Grid item xs={12} sm={7} md={5}>
                        <FormControl>
                            <SwitchWithLabel 
                                checked={consent.includeDataDeletionStandardText}
                                disabled={!isEditMode}
                                onChange={value => {
                                    setConsentCopy({...consent, includeDataDeletionStandardText: value})
                                }}
                            >
                                <h5>Include</h5>
                            </SwitchWithLabel>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container className={"mt-30"}>
                    <Grid item xs={12} sm={4} md={3}>
                        <label>Replace an existing consent text <LightTooltip title="Whether to include option to replace an existing consent text"
                                                                              placement="right-start" arrow><InfoIcon
                            color={"secondary"}/></LightTooltip></label>
                    </Grid>
                    <Grid item xs={12} sm={7} md={5}>
                        <FormControl>
                            <SwitchWithLabel 
                                checked={consent.includeReplaceAnExistingConsentText}
                                disabled={!isEditMode}
                                onChange={value => {
                                    setConsentCopy({...consent, includeReplaceAnExistingConsentText: value})
                                }}
                            >
                                <h5>Include</h5>
                            </SwitchWithLabel>
                        </FormControl>
                    </Grid>
                </Grid>

                {(props.mode === 'edit') &&
                    <Grid container className={"mt-20"}>
                      <Hidden xsDown><Grid item xs={12} sm={4} md={3}></Grid></Hidden>
                      <Grid item xs={12} sm={7} md={5}>
                        <ButtonBlock className={"text-align-right"}>
                          <Button onClick={() => handleCancel()} variant={"outlined"} color={"secondary"}>Cancel</Button>
                          <Button onClick={() => handleSave()} variant={"contained"} color={"secondary"}>Save</Button>
                        </ButtonBlock>
                      </Grid>
                    </Grid>
                }

            </div>
        </form>;

    return <Container>
        <h2 className={"page-title"}>Consent Configuration</h2>
        <h5 className="text-large">Money Simple</h5>

        <Grid container className={"mt-30"}>
            <Grid item xs={12} sm={11} md={8}>
                The Consent stage contains several steps, which may include a CDR value proposition; the data request;
                selecting a data holder; and the step before authentication.
            </Grid>
        </Grid>

        {form}
    </Container>
}

export default ConfigurationConsentSettings;