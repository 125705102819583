import React from "react";
import {Container, Link} from "@material-ui/core";

const AdminFooter = () => {
    return <Container maxWidth={"lg"}>
        <footer className="admin-footer">
            Powered by <Link color={"inherit"} underline={"always"}
                             href="https://illion.opendatasolutions.com">illion.opendatasolutions.com</Link> &nbsp; &copy; illion
            Open Data Solutions 2020.
        </footer>
    </Container>;
}

export default AdminFooter;
