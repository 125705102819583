import React from "react";
import PrimaryBlock from "../components/PrimaryBlock";
import DataHolder from "../models/DataHolder";
import { Container, Grid, Dialog, DialogContent, DialogActions, Button } from "@material-ui/core";
import ButtonBlock from "../components/ButtonBlock";
import { useHistory } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";

interface DataHolderConnectConfirmationProps {
    currentDataHolderSelection?: DataHolder,
    onAuthorisationAccept?: (newSelection: DataHolder) => void;
    next: string;
}

const DataHolderConnectConfirmation = (props: DataHolderConnectConfirmationProps) => {
    const history = useHistory();

    const [open, setOpen] = React.useState(false);

    const openPopup = () => {
        setOpen(true);
    };

    const closePopup = () => {
        setOpen(false);
    };

    const cancelProcess = () => {
        history.replace("/consent");
    };

    const handleContinue = () => {
        if (props.onAuthorisationAccept && props.currentDataHolderSelection) {
            props.onAuthorisationAccept(props.currentDataHolderSelection);
            history.push(props.next);
        }
    }

    return <>
        <Header />

        <PrimaryBlock>
            <Container maxWidth={"sm"} className={"p-0"}>
                <h4>Money Simple will securely connect with your bank: <em>{props.currentDataHolderSelection?.title}</em></h4>
                <p>By selecting 'Continue' you will be securely transferred to the {props.currentDataHolderSelection?.title}'s website.</p>
                <Grid container spacing={1}>
                    <Grid item xs={3} className="align-self-center">
                        <img alt="" src="/images/logo-illion-circle.svg" />
                    </Grid>
                    <Grid item xs={5} className="align-self-center">
                        <img alt="" src="/images/bank-connection.svg" />
                    </Grid>
                    <Grid item xs={4} className="align-self-center">
                        {props.currentDataHolderSelection &&
                            <img src={props.currentDataHolderSelection.logo} alt={props.currentDataHolderSelection.title} />
                        }
                    </Grid>
                </Grid>
                <ButtonBlock>
                    <Button onClick={openPopup} variant={"outlined"} color={"secondary"}>Cancel</Button>
                    <Button onClick={handleContinue} variant={"contained"} color={"secondary"}>Continue</Button>
                </ButtonBlock>
            </Container>
        </PrimaryBlock>

        {/* Cancellation popup */}
        <Dialog
            maxWidth={"sm"}
            open={open}
            onClose={closePopup}
        >
            <DialogContent>
                <h2>Cancel process?</h2>
                <p>By not sharing your bank data, we will not be able to automatically collect this information. However, you can still provide this information manually when setting up Money Simple.</p>
                <p>Are you sure you would like to cancel this process?</p>
            </DialogContent>
            <DialogActions>
                <Button onClick={cancelProcess} variant={"contained"} color={"secondary"}>Yes</Button>
                <Button onClick={closePopup} variant={"outlined"} color={"secondary"}>No</Button>
            </DialogActions>
        </Dialog>

        <Footer />
    </>;
}

export default DataHolderConnectConfirmation;
