import React, {useEffect, useState} from "react";
import {Grid, Hidden, Button} from "@material-ui/core";
import ButtonBlock from "../../components/ButtonBlock";
import Configuration from "../models/Configuration";
import GeneralSettings from "../models/GeneralSettings";
import ViewGeneralSettings from "./ViewGeneralSettings";
import EditGeneralSettings from "./EditGeneralSettings";

interface EditGeneralSettingsProps
{
    configuration: Configuration,
    mode: "edit" | "view",
    onConfigurationUpdate?: (newConfiguration: Configuration) => void,
    onCancel?: () => void,
}

interface EditGeneralSettingsState
{
    generalSettings: GeneralSettings;
    configurationName: string;
    configurationDescription: string;
}

const ConfigurationGeneralSettings = (props: EditGeneralSettingsProps) => {
    const [state, setState] = useState<EditGeneralSettingsState>({
        generalSettings: {...props.configuration.generalSettings},
        configurationName: props.configuration.name,
        configurationDescription: props.configuration.description,
    });

    useEffect(() => {
        setState((state) => ({
            ...state,
            generalSettings: { ...props.configuration.generalSettings }
        }))
    }, [props.mode, props.configuration.generalSettings]);

    const handleCancel = () => {
        props.onCancel && props.onCancel();
    }

    const handleSave = () => {
        const newConfiguration: Configuration = {
            ...props.configuration,
            name: state.configurationName,
            description: state.configurationDescription,
            generalSettings: state.generalSettings,
        }

        props.onConfigurationUpdate && props.onConfigurationUpdate(newConfiguration);
    };

    const handleGeneralSettingsChange = (newGeneralSettings: GeneralSettings) => {
        setState({
            ...state,
            generalSettings: newGeneralSettings,
        });
    }

    const handleConfigurationNameChange = (name: string) => {
        setState({
            ...state,
            configurationName: name,
        })
    }

    const handleConfigurationDescriptionChange = (description: string) => {
        setState({
            ...state,
            configurationDescription: description,
        })
    }

    return <>
        <h2 className={"page-title"}>General Settings</h2>
        <h5 className="text-large">Money Simple</h5>
        {(props.mode === "edit")
            ? <>
                <EditGeneralSettings
                    configurationDescription={state.configurationDescription}
                    configurationName={state.configurationName}
                    generalSettings={state.generalSettings}
                    onGeneralSettingsChange={handleGeneralSettingsChange}
                    onConfigurationNameChange={handleConfigurationNameChange}
                    onConfigurationDescriptionChange={handleConfigurationDescriptionChange}
                />
                <Grid container className={"mt-20"}>
                    <Hidden xsDown><Grid item xs={12} sm={4} md={3}></Grid></Hidden>
                    <Grid item xs={12} sm={7} md={5}>
                        <ButtonBlock className={"text-align-right"}>
                            <Button onClick={() => handleCancel()} variant={"outlined"} color={"secondary"}>Cancel</Button>
                            <Button onClick={() => handleSave()} variant={"contained"} color={"secondary"}>Save</Button>
                        </ButtonBlock>
                    </Grid>
                </Grid>
            </>
            : <ViewGeneralSettings configuration={props.configuration} generalSettings={state.generalSettings} />
        }
    </>;
}

export default ConfigurationGeneralSettings;