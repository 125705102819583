import React from "react";
import { Link } from 'react-router-dom';

interface HeaderProps {
    title?: string;
}

const Header = (props: HeaderProps) => {
    return <header className={"header"}>
        <div className={"top-bar"}>
            <Link id="logo" to={"/"}><img src={"/images/logo-illion.svg"} alt="illion" /></Link>
        </div>
        { props.title &&
            <div className="text-secondary">
                <h2 className={"page-title"}>{props.title}</h2>
            </div>
        }
    </header>
}

export default Header;
