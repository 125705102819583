import React, { useState } from "react";
import SecondaryBlock from "../components/SecondaryBlock";
import { Grid, Button, Breadcrumbs, Box, Container, Link, Typography, makeStyles, Dialog } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faDownload, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useHistory, useParams } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import SwitchWithLabel from "../components/SwitchWithLabel";
import LinkButton from "../components/LinkButton";
import ButtonBlock from "../components/ButtonBlock";
import ModalPopUpData from "../models/ModalPopUpData";
import { DialogContent } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
	consentDetailsHeader: {
		borderBottom: `1px dashed ${theme.palette.secondary.main}`
	}
}));

interface WithdrawConsentProps {
    dashboardPath: string;
    onDeleteOptionChange: (newOption: boolean) => void;
    currentDeleteOption: boolean | undefined;
}


const WithdrawConsent = (props: WithdrawConsentProps) => {
    const { id } = useParams<{ id: any }>();
    
    var newDeleteOption = false;
    if(props.currentDeleteOption){
        newDeleteOption = props.currentDeleteOption;
    }

	const [deleteDataOption, setDeleteDataOption] = useState(newDeleteOption);
    
    const modalPopupsData = [  
        {
            popUpHeading: "De-identification of data",
            popUpGeneralText: [],
            popupBoxContents:[],
            popupBlockContents:[
                {
                    heading: "What does it mean to de-identify data?",
                    content: [
                        {
                            text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin elit felis, hendrerit eu mauris nec, iaculis placerat nisi. Nunc id tellus orci. Integer faucibus mauris sit amet ipsum accumsan consequat."
                        }
                    ]
                },
                {
                    heading: "How will we de-identify your data?",
                    content: [
                        {
                            text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin elit felis, hendrerit eu mauris nec, iaculis placerat nisi. Nunc id tellus orci. Integer faucibus mauris sit amet ipsum accumsan consequat."
                        }
                    ]
                },
                {
                    heading: "How will we use your de-identified data?",
                    content: [
                        {
                            text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin elit felis, hendrerit eu mauris nec, iaculis placerat nisi. Nunc id tellus orci. Integer faucibus mauris sit amet ipsum accumsan consequat."
                        }
                    ]
                },
                {
                    heading: "Want more information?",
                    content: [
                        {
                            text: "Refer to our Consumer Data Right policy for more information on data de-identification."
                        }
                    ]
                }
                
            ],
        },
        {
            popUpHeading: "Data deletion",
            popUpGeneralText: [],
            popupBoxContents:[],
            popupBlockContents:[
                {
                    heading: "What does it mean to delete data?",
                    content: [
                        {
                            text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin elit felis, hendrerit eu mauris nec, iaculis placerat nisi. Nunc id tellus orci. Integer faucibus mauris sit amet ipsum accumsan consequat. "
                        }
                    ]
                },
                {
                    heading: "When will you delete my data?",
                    content: [
                        {
                            text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin elit felis, hendrerit eu mauris nec, iaculis placerat nisi. Nunc id tellus orci. Integer faucibus mauris sit amet ipsum accumsan consequat. "
                        }
                    ]
                },
                {
                    heading: "How will you delete my data?",
                    content: [
                        {
                            text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin elit felis, hendrerit eu mauris nec, iaculis placerat nisi. Nunc id tellus orci. Integer faucibus mauris sit amet ipsum accumsan consequat. "
                        }
                    ]
                },
                {
                    heading: "What about supporting third parties?",
                    content: [
                        {
                            text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin elit felis, hendrerit eu mauris nec, iaculis placerat nisi. Nunc id tellus orci. Integer faucibus mauris sit amet ipsum accumsan consequat. "
                        }
                    ]
                },
                {
                    heading: "Want more information?",
                    content: [
                        {
                            text: "Refer to our Consumer Data Right policy for more information on data de-identification. "
                        }
                    ]
                }
            ],
        }
    ];

	const history = useHistory();

	const [open, setOpen] = React.useState(false);
	const [popUpModalDatas, setPopUpModalDatas] = React.useState<ModalPopUpData>();

	const openPopup = (prop: ModalPopUpData) => {
        setOpen(true);
        setPopUpModalDatas(prop);
    };

	const closePopup = () => {
        setOpen(false);
    };

	const handleSave = () => {
		history.push(props.dashboardPath + `/consent-details/${id}/withdraw-confirmation`);
	};

	const deIdentifyPopup = () => {
		const identifypopupopupdatas = modalPopupsData[0];
        openPopup(identifypopupopupdatas);
	}

	const deletePopup = () => {
		const deletepopupopupdatas = modalPopupsData[1];
        openPopup(deletepopupopupdatas);
    }

	const popUpgeneralTexts = popUpModalDatas?.popUpGeneralText.map((item) =>
            <p>{item.content}</p>
    );
    
    const popUpBoxTexts = popUpModalDatas?.popupBoxContents.map((item) =>
        <>
            <h6>{item.heading}</h6>
            <p>{item.content}</p>
        </>
    );

    const popUpBlockTexts = popUpModalDatas?.popupBlockContents.map(item =>{
        return (
        <>
            <h6>{item.heading}</h6>
			<br />
            {
                item.content?.map(word =>{
                    return (
                        <p>{word.text}</p>
                    )
                })
            }
			<br />
        </>
        )
    });

    const handleDeleteDataOption = (currentOption : boolean) => {
        setDeleteDataOption(currentOption);
        if(props.onDeleteOptionChange){
            props.onDeleteOptionChange(currentOption);
        }
    }

    const handleGoBack = () => {
		history.push(props.dashboardPath + `/consent-details/${id}`);
	};

	const style = useStyles();

	return <>
		<Header />

		<Container maxWidth={"lg"}>
			<Breadcrumbs aria-label="breadcrumb">
				<Link color="inherit" href={props.dashboardPath}>Dashboard</Link>
				<Link color="inherit" href={props.dashboardPath + `/consent-details/${id}`}>View: Commonwealth Bank of Australia</Link>
				<span>Stop sharing</span>
			</Breadcrumbs>

			<div className={`consent-details-header ${style.consentDetailsHeader}`}>
				<Grid container spacing={3} className={"institution-card"}>
					<Grid item xs={12} md={9}>
						<Grid container>
							<Grid item xs={"auto"} sm={1}><img src="/images/data-holder-logos/commbank-logo-round.png" alt="" /></Grid>
							<Grid item xs={12} sm={11}>
								<h2>Commonwealth Bank of Australia</h2>
								<div id="card-misc">
									<span>Product: Money Simple</span>
									<span className={"status-active"}><FontAwesomeIcon icon={faCircle} /> Active</span>
									<Typography component={"span"} color={"secondary"}><FontAwesomeIcon icon={faDownload} /> <a href="/sample-url">Download confirmation of consent</a></Typography>
								</div>
							</Grid>
						</Grid>
					</Grid>
					{/* <Grid item xs={12} md={3} className={"text-align-right"}>
						<LinkButton targetlocation={props.dashboardPath + `/consent-details/${id}/withdraw`} variant={"contained"} color={"secondary"}>Stop sharing</LinkButton>
					</Grid> */}
				</Grid>
			</div>

			<Grid container spacing={3}>
				<Grid item xs={12}>
					<Link color={"secondary"} className={"go-back-link"} href="/#" onClick={(e : any) => {e.preventDefault(); handleGoBack(); }}><FontAwesomeIcon size={"sm"} icon={faArrowLeft} />Back</Link>
				</Grid>
			</Grid>

			<Grid container spacing={5}>
				<Grid item xs={12} md={6}>
					<Box mt={2}>
						<h2>Stop sharing</h2>
						<Typography component={'div'} color={"secondary"}><h4 className={"font-weight-normal"}>When you stop sharing</h4></Typography>
						<p>We will de-identify your data when we no longer need it to help you track your spending, and there Is no legal obligation to retain it.</p>
						<p><a href="/#" onClick={(e) => {e.preventDefault(); deIdentifyPopup();}} target="_blank">How and why we de-identify your data</a></p>
					</Box>
					<Box mt={4}>
						<SwitchWithLabel checked={deleteDataOption} onChange={value => { handleDeleteDataOption(value); }}>
							<h6>Delete my data instead</h6>
						</SwitchWithLabel>
						<p>If you don’t do this by 2 July 2021, your data will be de-identified.</p>
						<p><a href="/#" onClick={(e) => {e.preventDefault(); deletePopup();}} target="_blank">See how we delete your data</a></p>
					</Box>
				</Grid>
			</Grid>

			<Box mb={6}>
				<Grid container spacing={5} className={"mb-50"}>
					<Grid item xs={12} md={6}>
						<ButtonBlock className={"text-align-right mt-0"}>
							<LinkButton targetlocation={props.dashboardPath + `/consent-details/${id}`} variant={"outlined"} color={"secondary"}>Cancel</LinkButton>
							<Button onClick={handleSave} variant={"contained"} color={"secondary"}>Continue</Button>
						</ButtonBlock>
					</Grid>
				</Grid>
			</Box>
		</Container>

		<SecondaryBlock>
			Please read our <a href="/sample-url">Consumer Data Right policy</a> for some information on how Money Simple handles your data.
		</SecondaryBlock>

		{/* Information popup */}
        <Dialog
            id={"popup-3"}
            maxWidth={"sm"}
            open={open}
        >
            <CloseIcon 
               onClick={closePopup}
               className="close-popup-icon" 
            />
            <DialogContent>
                <h2>{popUpModalDatas?.popUpHeading}</h2>

                {popUpgeneralTexts}

                {(popUpBoxTexts?.length)?
                    <Box my={3} p={3} className={"background-grey"}>
                        {popUpBoxTexts}
                    </Box>
                    : ""
                }
                {popUpBlockTexts}
                <a href="/privacy-policy" target="_blank">View Consumer Data Right policy</a>
            </DialogContent>
        </Dialog>
		
		<Footer />
	</>;
}

export default WithdrawConsent;