import React from "react";
import {Box} from "@material-ui/core";
import LinkButton from "../../components/LinkButton";

interface AdminLoginProps {
    onLogin: () => void,
    basePath: string
}

const AdminLogin = (props: AdminLoginProps) => {
    return <div className="admin-login">
        <h2><strong>Admin Login</strong></h2>
        <Box mt={4}>
            <LinkButton targetlocation= {props.basePath} variant={"contained"} color={"secondary"} onClick={() => props.onLogin()}>Login</LinkButton>
        </Box>
    </div>;
}

export default AdminLogin;