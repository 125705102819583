import React from "react";

import {Link} from "react-router-dom";
import TabItemDescription from "../models/TabItemDescription";

interface TabsProps {
    tabs: Array<TabItemDescription>;
    basePath: string;
    backPath: string;
    activeTabIndex: number;
    configurationId: number;

    onTabChange?: (newTabIndex: number) => void;
}

const ViewEditConfigurationTabs = (props: TabsProps) => {
    return <div className={"activeTabOverride-bar"}>
        <ul className={"configuration-tabs"}>
            <li><Link to={props.backPath}>&lt; Return</Link></li>
            {props.tabs.map((tabItemData, index) => {
                return <li className={(index === props.activeTabIndex) ? "active" : ""}>
                    <div onClick={() => {props.onTabChange && props.onTabChange(index)}}>{tabItemData.text}</div>
                </li>;
            })}
        </ul>
    </div>
}

export default ViewEditConfigurationTabs;