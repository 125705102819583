import React, {useEffect, useState} from "react";
import {Switch as RouterSwitch, Route, Redirect, useLocation, useHistory} from "react-router-dom";
import {createMuiTheme, ThemeProvider, Theme, withStyles} from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";

import ProductValueProposition from "./pages/ProductValueProposition";
import CDRValueProposition from "./pages/CDRValueProposition";
import DataRequest from "./pages/DataRequest";
import DataHolderSelection from "./pages/DataHolderSelection";
import DataHolderConnectConfirmation from "./pages/DataHolderConnectConfirmation";
import PreviousDataDeleteConfirmation from "./pages/PreviousDataDeleteConfirmation";
import Dashboard from "./pages/Dashboard";
import DataHolder from "./models/DataHolder";

import {illionTheme, customTheme, styles, ThemeId} from "./Theme";
import {WithStyles} from "@material-ui/styles";
import Success from "./pages/Success";
import ConsentDetails from "./pages/ConsentDetails";
import WithdrawConsent from "./pages/WithdrawConsent";
import WithdrawConsentConfirmation from "./pages/WithdrawConsentConfirmation";
import WithdrawConsentSuccess from "./pages/WithdrawConsentSuccess";
import Admin from "./admin/pages/Admin";
import OpenBankingPlatformAPI from "./openbankingplatform/OpenBankingPlatformAPI";
import OpenBankingPlatformMockApi from "./openbankingplatform/OpenBankingPlatformMockApi";

interface AppState
{
    title: string;
    dataHolderCurrentSelection?: DataHolder;
    haveAuthorisationWith: Map<string, boolean>;
    theme: ThemeId;
    currentWithdrawConsentDataDeleteOption?: boolean;
}

function App(props: WithStyles<typeof styles>)
{
    const [state, setState] = useState<AppState>({
        title: "",
        haveAuthorisationWith: new Map<string, boolean>(),
        theme: "Illion"
    })

    const location = useLocation();
    useEffect(() => {
        const updatePageTitle = (newPageTitle: string) => {
            setState(state => ({
                ...state,
                title: newPageTitle,
            }))
        }

        if (location.pathname.includes("preconsent")) {
            updatePageTitle("");
        } else
            if (location.pathname.includes("consent")) {
                updatePageTitle("Consent");
            } else {
                updatePageTitle("");
            }
    }, [location.pathname])

    const handleDataHolderSelectionChange = (newSelection: DataHolder) => {
        setState({
            ...state,
            dataHolderCurrentSelection: {...newSelection},
        });
    }

    const handleAuthorisationAccept = (dataHolder: DataHolder) => {
        const newHaveAuthorisationWith = new Map(state.haveAuthorisationWith);
        newHaveAuthorisationWith.set(dataHolder.id, true);
        setState({
            ...state,
            haveAuthorisationWith: newHaveAuthorisationWith,
        })
    }

    const handleWithdrawConsentDeleteDataOption = (newOption: boolean) => {
        setState({
            ...state,
            currentWithdrawConsentDataDeleteOption: newOption,
        });
    }

    const theme = React.useMemo(() => {
        if (state.theme === "Illion") {
            return createMuiTheme(illionTheme);
        } else
            if (state.theme === "Custom") {
                return createMuiTheme(customTheme);
            } else {
                return createMuiTheme();
            }
    }, [state.theme]);

    const basePath = process.env.REACT_APP_BASE_PATH ?? '/consent';
    const adminBasePath = process.env.REACT_APP_ADMIN_BASE_PATH ?? '/admin';
    const dashboardPath = process.env.REACT_APP_DASHBOARD_BASE_PATH ?? '/dashboard';


    const apiUrl = process.env.REACT_APP_API_URL;
    if (!apiUrl) {
        throw Error("REACT_APP_API_URL is not defined in the configuration");
    }
    const obApi = new OpenBankingPlatformAPI(new URL(apiUrl), "bearer-access-token");
    const mockApi = new OpenBankingPlatformMockApi();

    const connectAnotherDataholderPath = basePath + "/consent/data-holder-selection";
    const history = useHistory();
    return (
        <>
            <ThemeProvider<Theme> theme={theme}>
                <CssBaseline/>
                <RouterSwitch>
                    <Route path={adminBasePath}>
                        <Admin basePath={adminBasePath} api={mockApi}/>
                    </Route>
                   
                    <Route exact path={basePath + "/preconsent"}>
                        <ProductValueProposition
                            next={basePath + "/preconsent/cdr-value"}
                        />
                    </Route>
                    <Route path={basePath + "/preconsent/cdr-value"}>
                        <CDRValueProposition
                            prev={basePath + "/preconsent"}
                            next={basePath + "/consent/data-request"}
                        />
                    </Route>
                    <Route path={basePath + "/consent/data-request"}>
                        <DataRequest
                            api={obApi}
                            prev={basePath + "/preconsent/cdr-value"}
                            next={basePath + "/consent/data-holder-selection"}
                            sharingPeriodIsMandatory={true}
                            onDeleteOptionChange={handleWithdrawConsentDeleteDataOption}
                            currentDeleteOption={state.currentWithdrawConsentDataDeleteOption}
                        />
                    </Route>
                    <Route path={basePath + "/consent/data-holder-selection"}>
                        <DataHolderSelection onSelectionChange={handleDataHolderSelectionChange}
                                             haveAuthorisationWith={state.haveAuthorisationWith}
                                             next={basePath + "/consent/previous-data"}
                        />
                    </Route>
                    <Route path={basePath + "/consent/previous-data"}>
                        <PreviousDataDeleteConfirmation
                            next = {basePath +'/data-holder-connect-confirmation'}
                            prev = {basePath + "/consent/data-holder-selection"}
                            onCancel = {() => history.push(basePath + "/preconsent")}
                            onDeleteOptionChange={handleWithdrawConsentDeleteDataOption}
                            currentDeleteOption={state.currentWithdrawConsentDataDeleteOption}
                        />
                    </Route>
                    <Route path={basePath + "/data-holder-connect-confirmation"}>
                        <DataHolderConnectConfirmation onAuthorisationAccept={handleAuthorisationAccept}
                                                       currentDataHolderSelection={state.dataHolderCurrentSelection}
                                                       next={basePath + "/consent/success"}/>
                    </Route>
                    <Route path={basePath + "/consent/success"}>
                        <Success
                            dashboardPath={dashboardPath}
                            connectAnotherDataHolderPath={connectAnotherDataholderPath}
                            currentDataHolderSelection={state.dataHolderCurrentSelection}
                        />
                    </Route>
                    <Route exact path={basePath + "/:token?"}>
                        <Redirect to={basePath + "/preconsent"}/> 
                        <ProductValueProposition
                            next={basePath + "/preconsent/cdr-value"}
                        />
                    </Route>

                    
                    <Route exact path={dashboardPath + "/consent-details/:id"}>
                        <ConsentDetails
                            dashboardPath={dashboardPath}
                            onDeleteOptionChange={handleWithdrawConsentDeleteDataOption}
                            currentDeleteOption={state.currentWithdrawConsentDataDeleteOption}
                        />
                    </Route>
                    <Route path={dashboardPath + "/consent-details/:id/withdraw"}>
                        <WithdrawConsent
                            dashboardPath={dashboardPath}
                            onDeleteOptionChange={handleWithdrawConsentDeleteDataOption}
                            currentDeleteOption={state.currentWithdrawConsentDataDeleteOption}
                        />
                    </Route>
                    <Route path={dashboardPath + "/consent-details/:id/withdraw-confirmation"}>
                        <WithdrawConsentConfirmation
                            dashboardPath={dashboardPath}
                        />
                    </Route>
                    <Route path={dashboardPath + "/consent-details/:id/withdraw-success"}>
                        <WithdrawConsentSuccess
                            dashboardPath={dashboardPath}
                        />
                    </Route>
                    <Route exact path={dashboardPath + "/:token?"}>
                        <Redirect to={dashboardPath}/> 
                        <Dashboard
                            connectDataHolderPath={connectAnotherDataholderPath}
                            dashboardPath={dashboardPath}
                        />
                    </Route>
                    <Route>
                        <div>
                            <h3>Not found</h3>
                            <p>The page you're requesting was not found.</p>
                        </div>
                    </Route>
                </RouterSwitch>
            </ThemeProvider>
        </>
    );
}

export default withStyles(styles)(App);