import React, {useState} from "react";
import Configuration from "../models/Configuration";
import TabItemDescription from "../models/TabItemDescription";
import {useParams} from "react-router-dom";
import {Button, Grid, Hidden} from "@material-ui/core";
import ButtonBlock from "../../components/ButtonBlock";
import ConfigurationConsentSettings from "../components/ConfigurationConsentSettings";
import ConfigurationGeneralSettings from "../components/ConfigurationGeneralSettings";
import ConfigurationPreConsentSettings from "../components/ConfigurationPreConsentSettings";
import ViewEditConfigurationTabs from "../components/ViewEditConfigurationTabs";
import LinkButton from "../../components/LinkButton";

interface ViewEditConfigurationProps
{
    configurations: Array<Configuration>,
    basePath: string,
    onUpdateConfiguration?: (configurationId: number, newConfiguration: Configuration) => void,
}

interface ViewEditConfigurationState
{
    mode: "view" | "edit",
    activeTabIndex: number,
}

const ViewEditConfiguration = (props: ViewEditConfigurationProps) => {
    const {id} = useParams<{ id: string, tabId: string }>();
    const configurationId = parseInt(id);
    const configuration = props.configurations.find(configuration => configuration.id === configurationId);
    if (!configuration) {
        throw Error("Invalid parameter");
    }

    const tabs: Array<TabItemDescription> = [
        {
            id: `general-settings`,
            text: `General Settings`,
        },
        {
            id: `pre-consent`,
            text: `Pre-Consent`,
        },
        {
            id: `consent`,
            text: `Consent`,
        },
    ];

    const [state, setState] = useState<ViewEditConfigurationState>(
        {
            mode: "view",
            activeTabIndex: 0,
        }
    );

    const nextTab = () => {
        const newTabIndex = (state.activeTabIndex + 1 >= tabs.length) ? 0 : state.activeTabIndex + 1;
        setState({
            ...state,
            activeTabIndex: newTabIndex,
        })
    }

    const prevTab = () => {
        const newTabIndex = (state.activeTabIndex + 1 > tabs.length) ? 0 : state.activeTabIndex - 1;
        setState({
            ...state,
            activeTabIndex: newTabIndex,
        })
    }

    const switchToTab = (newTabIndex: number) => {
        setState({
            ...state,
            activeTabIndex: newTabIndex,
        })
    }

    const switchMode = (newMode: "view" | "edit") => {
        setState({
            ...state,
            mode: newMode,
        })
    }

    const handleConfigurationUpdate = (newConfiguration: Configuration) => {
        props.onUpdateConfiguration && props.onUpdateConfiguration(configurationId, newConfiguration);
        switchMode("view");
    }

    const handleCancel = () => {
        switchMode("view");
    }

    const editor = ((tabIndex: number) => {
        switch (tabIndex) {
            case 0:
                return <ConfigurationGeneralSettings configuration={configuration} mode={state.mode}
                                                     onCancel={handleCancel}
                                                     onConfigurationUpdate={handleConfigurationUpdate}/>
            case 1:
                return <ConfigurationPreConsentSettings configuration={configuration} mode={state.mode}
                                                        onCancel={handleCancel}
                                                        onConfigurationUpdate={handleConfigurationUpdate}/>
            case 2:
                return <ConfigurationConsentSettings configuration={configuration} mode={state.mode}
                                                     onCancel={handleCancel}
                                                     onConfigurationUpdate={handleConfigurationUpdate}/>
            default:
                return <></>
        }
    })(state.activeTabIndex);

    return <div className={"view-edit-configuration"}>
        <div className={"tabs-container"}>
            <ViewEditConfigurationTabs
                tabs={tabs}
                activeTabIndex={state.activeTabIndex}
                backPath={props.basePath}
                basePath={props.basePath + "/view"}
                configurationId={configurationId}
                onTabChange={switchToTab}
            />
        </div>

        {editor}

        <Grid container className={"mt-20"}>
            <Hidden xsDown><Grid item xs={12} sm={4} md={3}></Grid></Hidden>
            <Grid item xs={12} sm={7} md={5}>
                <ButtonBlock className={"text-align-right"}>
                    {(state.mode === "view") &&
                    <>
                        {(state.activeTabIndex > 0) &&
                        <Button onClick={() => prevTab()} variant={"contained"} color={"secondary"}>Previous</Button>
                        }

                      <Button onClick={() => switchMode("edit")} variant={"contained"} color={"secondary"}>Edit
                        Settings</Button>

                        {(state.activeTabIndex < tabs.length - 1)
                            ? <Button onClick={() => nextTab()} variant={"contained"} color={"secondary"}>Next</Button>
                            : <LinkButton targetlocation={props.basePath} variant={"contained"}
                                          color={"secondary"}>Done</LinkButton>
                        }
                    </>
                    }

                </ButtonBlock>
            </Grid>
        </Grid>
    </div>;
}

export default ViewEditConfiguration;
