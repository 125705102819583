import React, { PropsWithChildren } from "react";
import { makeStyles } from "@material-ui/core";

interface HighlightBlockProps {
    className?: string;
}

const useStyles = makeStyles((theme) => ({
    infoBackground: {
        backgroundColor: theme.palette.info.main
    }
}));

const HighlightBlock = (props: PropsWithChildren<HighlightBlockProps>) => {
    const style = useStyles();
    return <div className={props.className + " highlight-block " + style.infoBackground}>{props.children}</div>;
}

export default HighlightBlock;
