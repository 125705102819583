import React from "react";
import SecondaryBlock from "../components/SecondaryBlock";
import { Grid, Button, Breadcrumbs, Box, Container, Link, Typography, makeStyles } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faDownload, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useHistory, useParams } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import LinkButton from "../components/LinkButton";
import ButtonBlock from "../components/ButtonBlock";

const useStyles = makeStyles((theme) => ({
	consentDetailsHeader: {
		borderBottom: `1px dashed ${theme.palette.secondary.main}`
	}
}));

interface WithdrawConsentConfirmationProps {
	dashboardPath: string;
}

const WithdrawConsentConfirmation = (props: WithdrawConsentConfirmationProps) => {
	const { id } = useParams<{ id: any }>();
    
	const history = useHistory();

	const handleSave = () => {
		history.push(props.dashboardPath + `/consent-details/${id}/withdraw-success`);
	};

	const style = useStyles();

	return <>
		<Header />

		<Container maxWidth={"lg"}>
			<Breadcrumbs aria-label="breadcrumb">
				<Link color="inherit" href={props.dashboardPath}>Dashboard</Link>
				<Link color="inherit" href={props.dashboardPath + `/consent-details/${id}`}>Commonwealth Bank of Australia</Link>
				<span>Stop sharing</span>
			</Breadcrumbs>

			<div className={`consent-details-header ${style.consentDetailsHeader}`}>
				<Grid container spacing={3} className={"institution-card"}>
					<Grid item xs={12} md={9}>
						<Grid container>
							<Grid item xs={"auto"} sm={1}><img src="/images/data-holder-logos/commbank-logo-round.png" alt="" /></Grid>
							<Grid item xs={12} sm={11}>
								<h2>Commonwealth Bank of Australia</h2>
								<div id="card-misc">
									<span>Product: Money Simple</span>
									<span className={"status-active"}><FontAwesomeIcon icon={faCircle} /> Active</span>
									<Typography component={"span"} color={"secondary"}><FontAwesomeIcon icon={faDownload} /> <a href="/sample-url">Download confirmation of consent</a></Typography>
								</div>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</div>

			<Grid container spacing={3}>
				<Grid item xs={12}>
					<Link color={"secondary"} className={"go-back-link"} href={props.dashboardPath + `/consent-details/${id}/withdraw`}><FontAwesomeIcon size={"sm"} icon={faArrowLeft} />Back</Link>
				</Grid>
			</Grid>

			<Grid container spacing={5}>
				<Grid item xs={12} md={6}>
					<Box mt={2}>
						<h2>Stop sharing</h2>
						<Typography component={'div'} color={"secondary"}><h4 className={"font-weight-normal"}>Impacts to your service</h4></Typography>
						<p>We will stop collecting and using the data you shared with us below.</p>

                        <p>Without this information we will be limited in our ability to help you track your budget.</p>
					</Box>
					<Box mt={4}>
						<h6>Direct debits and scheduled payments</h6>
						<p>If you stop sharing these details we will no longer be able to identify how much money you have been spending and the amount of regular payments you make.</p>
						
					</Box>
                    <Box mt={4}>
						<h6>Transaction details</h6>
                        <p>If you stop sharing these details we will no longer be able to identify how much money you have been spending.</p>
					</Box>
				</Grid>
			</Grid>

			<Box mb={6}>
            <Typography component={'div'} color={"secondary"}><h4 className={"font-weight-normal"}>Do you want to stop sharing?</h4></Typography>
				<Grid container spacing={5} className={"mb-50"}>
					<Grid item xs={12} md={6}>
						<ButtonBlock className={"text-align-right mt-0"}>
							<LinkButton targetlocation={props.dashboardPath + `/consent-details/${id}`} variant={"outlined"} color={"secondary"}>No, go back</LinkButton>
							<Button onClick={handleSave} variant={"contained"} color={"secondary"}>Yes, stop sharing</Button>
						</ButtonBlock>
					</Grid>
				</Grid>
			</Box>
		</Container>

		<SecondaryBlock>
			Please read our <a href="/sample-url">Consumer Data Right policy</a> for some information on how Money Simple handles your data.
		</SecondaryBlock>

		<Footer />
	</>;
}

export default WithdrawConsentConfirmation;