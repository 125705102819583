import React from "react";
import {Container} from "@material-ui/core";
import Configuration from "../models/Configuration";
import {Route, useRouteMatch} from "react-router-dom";
import {Switch as RouterSwitch} from "react-router";
import ViewEditConfiguration from "./ViewEditConfiguration";
import CreateConfiguration from "./CreateConfiguration";
import ConfigurationDashboard from "../components/ConfigurationDashboard";

interface ConfigurationsProps
{
    configurations?: Array<Configuration>;
    onUpdateConfiguration?: (configurationId: number, newConfiguration: Configuration) => void,
    onCreateConfiguration?: (name: string, description: string) => void,
    basePath: string
}

const Configurations = (props: ConfigurationsProps) => {
    const {path} = useRouteMatch();
    return <Container>
        <h2><strong>Welcome to the illion Open Data Solutions Open Banking Admin Portal</strong></h2>
        {(props.configurations)
            ? <RouterSwitch>
                <Route path={`${path}/create`}>
                    <CreateConfiguration 
                        onCreateConfiguration={props.onCreateConfiguration}
                        basePath={props.basePath}
                    />
                </Route>

                <Route path={`${path}/open/:id`}>
                    <ViewEditConfiguration configurations={props.configurations}
                                           onUpdateConfiguration={props.onUpdateConfiguration}
                                           basePath={path}
                    />
                </Route>

                <Route path={"/"}>
                    <ConfigurationDashboard 
                        configurations={props.configurations}
                        basePath={props.basePath}
                    />
                </Route>
            </RouterSwitch>
            : "Loading..."
        }

    </Container>;
}

export default Configurations;
