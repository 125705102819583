import React, {useCallback, useEffect, useState} from "react";
import AccreditationBadge from "../components/AccreditationBadge";
import PrimaryBlock from "../components/PrimaryBlock";
import SecondaryBlock from "../components/SecondaryBlock";
import {
    Container,
    Box,
    Select,
    MenuItem,
    FormControl,
    Dialog,
    DialogContent,
    DialogActions,
    Button
} from "@material-ui/core";
import DataClusterCard from "../components/DataClusterCard";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleRight} from "@fortawesome/free-solid-svg-icons";
import HighlightBlock from "../components/HighlightBlock";
import SwitchWithLabel from "../components/SwitchWithLabel";
import AdditionalUseChoices, {AdditionalUseChoiceModel} from "../components/MultipleChoicedialog";
import {useHistory} from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";
import CloseIcon from '@material-ui/icons/Close';
import ModalPopUpData from "../models/ModalPopUpData";
import OpenBankingPlatformAPIInterface from "../openbankingplatform/OpenBankingPlatformAPIInterface";
import DataClusterOptionSet from "../models/DataClusterOptionSet";
import OpenBankingPlatformMockApi from "../openbankingplatform/OpenBankingPlatformMockApi";

interface DataRequestProps
{
    api: OpenBankingPlatformAPIInterface;
    prev: string;
    next: string;
    sharingPeriodIsMandatory: boolean;
    onDeleteOptionChange: (newOption: boolean) => void;
    currentDeleteOption: boolean | undefined;
}

interface DataRequestState
{
    dataClustersWithOptions?: Array<DataClusterOptionSet>;
    additionalUseChoices: Array<AdditionalUseChoiceModel>;
    sharingPeriod?: string;
    apiHasReturnedAnError: boolean;
    modalPopUpData: Array<ModalPopUpData>;
}

const DataRequest = (props: DataRequestProps) => {

    var newDeleteOption = false;
    if(props.currentDeleteOption){
        newDeleteOption = props.currentDeleteOption;
    }
    const [deleteDataOption, setDeleteDataOption] = useState(newDeleteOption);

    const [state, setState] = useState<DataRequestState>({
        additionalUseChoices: [
            {
                label: "Allow illion to use your data to suggest ways to save money",
                isSelected: false,
            },
            {
                label: "Allow illion to analyse your spending to send you product offers",
                isSelected: false,
            },
        ],
        modalPopUpData:[
            {
                popUpHeading: "Supporting third parties",
                popUpGeneralText:[
                    {
                        content: "Supporting third parties are companies that work with illion to help you lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                    },
                    {
                        content: "They can access the data you have agreed to share with illion, but it can only be used for the purpose(s) you consented."
                    }
                ],
                popupBoxContents:[
                    {
                        heading:"Lorem Ipsum Business",
                        content:"Curabitur vehicula, massa non tincidunt pellentesque, elit quam porta est, auctor vehicula eros nisl ut nisi. Aenean fringilla leo at porta venenatis."
                    },
                    {
                        heading:"Lorem Ipsum Business",
                        content:"Curabitur vehicula, massa non tincidunt pellentesque, elit quam porta est, auctor vehicula eros nisl ut nisi. Aenean fringilla leo at porta venenatis."
                    }
                ],
                popupBlockContents:[
                    {
                        heading: "Want more information?",
                        content: [
                            {
                                text : "Refer to our Consumer Data Right policy for more information on our supporting third parties."
                            },
                            {
                                text : "You can also request more information related to supporting third parties from us."
                            }
                        ]
                        
                    }
                ],
            },
            {
                popUpHeading: "De-identification of data",
                popUpGeneralText: [],
                popupBoxContents:[],
                popupBlockContents:[
                    {
                        heading: "What does it mean to de-identify data?",
                        content: [
                            {
                                text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin elit felis, hendrerit eu mauris nec, iaculis placerat nisi. Nunc id tellus orci. Integer faucibus mauris sit amet ipsum accumsan consequat."
                            }
                        ]
                    },
                    {
                        heading: "How will we de-identify your data?",
                        content: [
                            {
                                text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin elit felis, hendrerit eu mauris nec, iaculis placerat nisi. Nunc id tellus orci. Integer faucibus mauris sit amet ipsum accumsan consequat."
                            }
                        ]
                    },
                    {
                        heading: "How will we use your de-identified data?",
                        content: [
                            {
                                text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin elit felis, hendrerit eu mauris nec, iaculis placerat nisi. Nunc id tellus orci. Integer faucibus mauris sit amet ipsum accumsan consequat."
                            }
                        ]
                    },
                    {
                        heading: "Want more information?",
                        content: [
                            {
                                text: "Refer to our Consumer Data Right policy for more information on data de-identification."
                            }
                        ]
                    }
                    
                ],
            },
            {
                popUpHeading: "Data deletion",
                popUpGeneralText: [],
                popupBoxContents:[],
                popupBlockContents:[
                    {
                        heading: "What does it mean to delete data?",
                        content: [
                            {
                                text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin elit felis, hendrerit eu mauris nec, iaculis placerat nisi. Nunc id tellus orci. Integer faucibus mauris sit amet ipsum accumsan consequat. "
                            }
                        ]
                    },
                    {
                        heading: "When will you delete my data?",
                        content: [
                            {
                                text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin elit felis, hendrerit eu mauris nec, iaculis placerat nisi. Nunc id tellus orci. Integer faucibus mauris sit amet ipsum accumsan consequat. "
                            }
                        ]
                    },
                    {
                        heading: "How will you delete my data?",
                        content: [
                            {
                                text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin elit felis, hendrerit eu mauris nec, iaculis placerat nisi. Nunc id tellus orci. Integer faucibus mauris sit amet ipsum accumsan consequat. "
                            }
                        ]
                    },
                    {
                        heading: "What about supporting third parties?",
                        content: [
                            {
                                text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin elit felis, hendrerit eu mauris nec, iaculis placerat nisi. Nunc id tellus orci. Integer faucibus mauris sit amet ipsum accumsan consequat. "
                            }
                        ]
                    },
                    {
                        heading: "Want more information?",
                        content: [
                            {
                                text: "Refer to our Consumer Data Right policy for more information on data de-identification. "
                            }
                        ]
                    }
                ],
            }
        ],
        apiHasReturnedAnError: false,
    });

    // Making sure getDataClusters function does not change (causing following useEffect to run again)
    // eslint-disable-next-line
    const getDataClusters = useCallback(props.api.getDataClusters, []);

    useEffect(() => {
            (async () => {
                try {
                    const loadedDataClusters = await getDataClusters();

                    const newDataClustersWithOptions = loadedDataClusters.map((loadedDataCluster) => {
                            const newDataClusterOptionSet: DataClusterOptionSet = {
                                clusterId: loadedDataCluster.id,
                                clusterName: loadedDataCluster.title,
                                authorisationScopeId: loadedDataCluster.scopes.join(","),
                                clusterPermissionNames: loadedDataCluster.data_list,
                                purposeInformation: loadedDataCluster.description,
                                isEnabled: loadedDataCluster.status === 'active',
                                isMandatory: loadedDataCluster.is_mandatory,
                                isSelected: false
                            }

                            return newDataClusterOptionSet;
                        }
                    );

                    setState((state) => ({
                        ...state,
                        dataClustersWithOptions: newDataClustersWithOptions,
                    }));
                } catch (error) {
                    // TODO: This is a fallback that should be removed in production. It is only temporary and ensures interface still works even if the server is not yet configured
                    const mockApi = new OpenBankingPlatformMockApi();
                    const mockDataClusters = await mockApi.getDataClustersWithOptions();
                    const newDataClustersWithOptions = mockDataClusters.map((mockDataCluster) => {
                            const newDataClusterOptionSet: DataClusterOptionSet = {
                                ...mockDataCluster
                            }

                            return newDataClusterOptionSet;
                        }
                    );

                    setState((state) => ({
                        ...state,
                        dataClustersWithOptions: newDataClustersWithOptions,
                    }))
                    // End fallback code


                    // TODO Uncomment this code when fallback is removed
                    // setState((state)=>({
                    //     ...state,
                    //     apiHasReturnedAnError: true,
                    // }))
                }
            })();
        },
        [getDataClusters]
    )

    const history = useHistory();

    const [open1, setOpen1] = React.useState(false);
    const [open2, setOpen2] = React.useState(false);
    const [open3, setOpen3] = React.useState(false);
    const [popUpModalDatas, setPopUpModalDatas] = React.useState<ModalPopUpData>();

    const openPopup1 = () => {
        setOpen1(true);
    };

    const openPopup2 = () => {
        setOpen2(true);
    };

    const openPopup3 = (currentPopUpData: ModalPopUpData) => {
        setOpen3(true);
        setPopUpModalDatas(currentPopUpData);
    };

    const closePopup1 = () => {
        setOpen1(false);
    };

    const closePopup2 = () => {
        setOpen2(false);
    };

    const closePopup3 = () => {
        setOpen3(false);
    };

    const cancelProcess = () => {
        history.replace("/consent");
    };

    const handleConsentButtonClick = () => {
        if (!state.dataClustersWithOptions) {
            return;
        }

        let selected = false;
        for (let i = 0; i < state.dataClustersWithOptions.length; i++) {
            if (state.dataClustersWithOptions[i].isSelected) selected = true;
        }
        if (!selected)
            openPopup2();
        else
            history.push(props.next);
    };

    const setSharingPeriod = (newSharingPeriod: string) => {
        if (newSharingPeriod==='') {
            setState({...state, sharingPeriod: undefined});
        } else {
            setState({...state, sharingPeriod: newSharingPeriod});
        }
    }

    const handleDataClusterCheckboxChange = (newSelection: boolean, newSelectionClusterId: number) => {
        if (!state.dataClustersWithOptions) {
            return;
        }

        const newDataClusters = state.dataClustersWithOptions.map((dataCluster) => {
            if (dataCluster.clusterId === newSelectionClusterId) {
                const newDataClusterWithUpdatedSelection: DataClusterOptionSet = {
                    ...dataCluster,
                    isSelected: newSelection,
                }

                return newDataClusterWithUpdatedSelection;
            } else {
                return dataCluster;
            }
        })

        setState({
            ...state,
            dataClustersWithOptions: newDataClusters,
        })
    }

    const handleAdditionalUseChange = (newSelection: boolean, newSelectionIndex: number) => {
        const newAdditionalUseChoices = state.additionalUseChoices.map((additionalUseChoice, index) => {
            if (index === newSelectionIndex) {
                const newAdditionalUseChoice: AdditionalUseChoiceModel = {
                    ...additionalUseChoice,
                    isSelected: newSelection,
                }

                return newAdditionalUseChoice;
            } else {
                return additionalUseChoice;
            }
        })

        setState({
            ...state,
            additionalUseChoices: newAdditionalUseChoices,
        })
    }
   
    const providerPopup = () => {
        const providerpopupopupdatas = state.modalPopUpData[0];
        openPopup3(providerpopupopupdatas);
    }

    const identifyDataPopup = () => {
        const identifypopupopupdatas = state.modalPopUpData[1];
        openPopup3(identifypopupopupdatas);
    }

    const deleteDataPopup = () => {
        const deletepopupopupdatas = state.modalPopUpData[2];
        openPopup3(deletepopupopupdatas);
    }

    const popUpgeneralTexts = popUpModalDatas?.popUpGeneralText.map((item) =>
            <p>{item.content}</p>
    );
    
    const popUpBoxTexts = popUpModalDatas?.popupBoxContents.map((item) =>
        <>
            <h6>{item.heading}</h6>
            <p>{item.content}</p>
        </>
    );

    const popUpBlockTexts = popUpModalDatas?.popupBlockContents.map(item =>{
        return (
        <>
            <h6>{item.heading}</h6>
            <br />
            {
                item.content?.map(word =>{
                    return (
                        <p>{word.text}</p>
                    )
                })
            }
            <br />
        </>
        )
    });

    const dataClusters = (state.dataClustersWithOptions)
        ? <>
            <p className="mandatory-fields-info">
                Fields marked with * are mandatory
            </p>
            {state.dataClustersWithOptions.map((dataCluster, index) =>
                <>
                    {(dataCluster.isEnabled)
                        ? <DataClusterCard
                            {...dataCluster}
                            key={dataCluster.clusterId}
                            onSelectionChange={(newSelection: boolean) => {
                                handleDataClusterCheckboxChange(newSelection, index);
                            }}
                        />
                        : ""
                    }
                </>
            )}
        </>
        : "";

    const allMandatoryDataClustersAreSelected: boolean =
        (state.dataClustersWithOptions)
            ? state.dataClustersWithOptions.every(dataCluster => !dataCluster.isMandatory || dataCluster.isSelected)
            : false;

    const sharingPeriodIsSelectedOrNotRequired = !props.sharingPeriodIsMandatory || state.sharingPeriod!==undefined;

    const allMandatoryFieldsAreSelected = allMandatoryDataClustersAreSelected && sharingPeriodIsSelectedOrNotRequired;

    const handleDataDeleteOption = (currentOption: boolean) => {
        setDeleteDataOption(currentOption);
        if(props.onDeleteOptionChange){
            props.onDeleteOptionChange(currentOption);
        }   
    }

    return <div className={"data-request"}>
        <Header/>

        <PrimaryBlock>
            <Container maxWidth="sm" className="p-0">
                <h2><strong>Data request</strong></h2>
                <p>We need to collect the below information for a maximum of 12 months. We will only use it to help you
                    manage your
                    budget for this financial year.</p>
                <AccreditationBadge
                    companyName={"illion Australia Pty Ltd"}
                    dataRecepientId={"123456"}
                    displayStyle={2}
                />

                {(state.dataClustersWithOptions)
                    ? <>
                        <h3><FontAwesomeIcon icon={faAngleRight}/> Data we need</h3>
                        <p>Please select the data you would like to share. The more you share, the more accurately you
                            can track
                            your spending. We will only ask for what we need.</p>
                        <form>
                            {dataClusters}
                        </form>
                        <h3><FontAwesomeIcon icon={faAngleRight}/> Additional uses of your data</h3>
                        <AdditionalUseChoices
                            title={"Recommended for a better user experience"}
                            choices={state.additionalUseChoices}
                            onChoiceChange={handleAdditionalUseChange}
                        />
                        <h3>Supporting third parties</h3>
                        <p>Supporting third parties will help provide this service. They will be bound by the same terms
                            of this arrangement.</p>
                        <p><a href="/#" className={"popup-link"} onClick={(e) => {
                            e.preventDefault();
                            providerPopup();
                        }}>See list of providers</a></p>
                        <h3><FontAwesomeIcon icon={faAngleRight}/> Key dates</h3>
                        <HighlightBlock className="data-request-card">
                            <h6>Sharing period
                                {(props.sharingPeriodIsMandatory) &&
                                <span className="mandatory-field-indicator">
                            &nbsp;*
                        </span>
                                }
                            </h6>
                            <p>Please select the period of time you would like to share your data.</p>
                            <Box mb={3}>
                                <FormControl variant="outlined">
                                    <Select
                                        id="sharing-period"
                                        name="sharing-period"
                                        value={state.sharingPeriod}
                                        onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                                            setSharingPeriod(event.target.value as string)
                                        }}
                                    >
                                        <MenuItem value={""}>- Please select -</MenuItem>
                                        <MenuItem value={"once-off"}>Once-off</MenuItem>
                                        <MenuItem value={"1 month"}>1 Month</MenuItem>
                                        <MenuItem value={"3 months"}>3 Months</MenuItem>
                                        <MenuItem value={"6 months"}>6 Months</MenuItem>
                                        <MenuItem value={"12 months"}>12 Months</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                            <h6>How often we’ll access your data</h6>
                            {state.sharingPeriod !== 'once-off' ? (
                                <p>We will do this every time you log in. This will be on-going for the
                                    next {state.sharingPeriod}.</p>
                            ) : (
                                <p>We will do this once.</p>
                            )}
                        </HighlightBlock>
                        <h3><FontAwesomeIcon icon={faAngleRight}/> What happens to your data after we’ve used it</h3>
                        <p>We will de-identify your data when we no longer need it to help you track your spending, and
                            there Is no legal obligation to retain it.</p>
                        <p><a href="/#" className={"popup-link"} onClick={(e) => {
                            e.preventDefault();
                            identifyDataPopup();
                        }}>How and why we de-identify your data</a></p>

                        <br/>
                        <SwitchWithLabel
                            checked={deleteDataOption}
                            onChange={value => {
                                handleDataDeleteOption(value);
                            }}
                        >
                            <p><strong> Delete my data instead </strong></p>
                        </SwitchWithLabel>
                        {(state.sharingPeriod !== 'once-off') &&
                        <p>You can also tell us to delete your data by going to Menu {'>'} Data Sharing or by writing to
                          datasharing@illion.com.au.</p>
                        }
                        {state.sharingPeriod !== 'once-off' ? (
                            <p>If you don't do this by 02 October 2021, your data will be de-identified.</p>
                        ) : (
                            <p>If you don’t do this, your data will be de-identified.</p>
                        )}
                        <p><a href="/#" className={"popup-link"} onClick={(e) => {
                            e.preventDefault();
                            deleteDataPopup()
                        }}>See how we delete your data</a></p>

                        <h3><FontAwesomeIcon icon={faAngleRight}/> Managing your data</h3>
                        <h6>Where to manage this arrangement</h6>
                        <p>All this information will be made available on your Data Sharing dashboard. You can access
                            this by going to Data Sharing via the navigation menu.</p>
                        <h6>If you want to stop sharing this data</h6>
                        <p>You can stop us collecting and using your data on your Data Sharing dashboard or by writing
                            to <a href={"mailto:datasharing@illion.com.au"}>datasharing@illion.com.au</a></p>
                    </>
                    : (state.apiHasReturnedAnError)
                        ? <>
                            <h3 className={"error-title"}>Error</h3>
                            <p className={"error-text"}>We're sorry but our system has encountered an error. Please try
                                again later or contact support.</p>
                        </>
                        : <h3>Loading...</h3>
                }
            </Container>
        </PrimaryBlock>

        <SecondaryBlock
            header="Do you consent to share this data with us?"
            ctaButtons={[
                <Button onClick={openPopup1} variant={"outlined"} color={"secondary"}>I don't consent</Button>,
                <Button onClick={handleConsentButtonClick} variant={"contained"} color={"secondary"}
                        disabled={!allMandatoryFieldsAreSelected}>I consent</Button>
            ]}
        >
            <Container maxWidth={"sm"} className={"p-0"}>
                <p>Selecting ‘I consent’ won’t give us access to your data just yet. We will need to connect you to your
                    bank to confirm this decision.</p>
                {(allMandatoryFieldsAreSelected)
                    ? ""
                    : <p className="mandatory-fields-hint">Please check all mandatory form fields before
                        proceeding.</p>
                }
            </Container>
        </SecondaryBlock>

        {/* Cancellation popup */}
        <Dialog
            id={"popup-1"}
            maxWidth={"sm"}
            open={open1}
            onClose={closePopup1}
        >
            <DialogContent>
                <h2>Are you sure?</h2>
                <p>By not giving your consent to share your bank data we will not be able to automatically collect this
                    information. However, you can still provide this information manually when setting up Money
                    Simple.</p>
                <p>Are you sure you would like to cancel this process?</p>
            </DialogContent>
            <DialogActions>
                <Button onClick={cancelProcess} variant={"contained"} color={"secondary"}>Yes</Button>
                <Button onClick={closePopup1} variant={"outlined"} color={"secondary"}>No</Button>
            </DialogActions>
        </Dialog>

        {/* Empty selection popup */}
        <Dialog
            id={"popup-2"}
            maxWidth={"sm"}
            open={open2}
            onClose={closePopup2}
        >
            <DialogContent>
                <h2>Please select data to consent</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque quam dui, mattis tempus metus
                    porttitor, luctus lacinia ex. Suspendisse nec elit dignissim, cursus dui id, pharetra ex.</p>
                <p>We require the following data to proceed:</p>
                <ul>
                    <li>Name, occupation, contact details</li>
                    <li>Direct debits and scheduled payments</li>
                    <li>Transactions details</li>
                </ul>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
            </DialogContent>
            <DialogActions>
                <Button onClick={closePopup2} variant={"contained"} color={"secondary"}>Go back</Button>
            </DialogActions>
        </Dialog>

        {/* Information popup */}

        <Dialog
            id={"popup-3"}
            maxWidth={"sm"}
            open={open3}
        >
            <CloseIcon
                onClick={closePopup3}
                className="close-popup-icon"
            />
            <DialogContent>
                <h2>{popUpModalDatas?.popUpHeading}</h2>

                {popUpgeneralTexts}

                {(popUpBoxTexts?.length)?
                    <Box my={3} p={3} className={"background-grey"}>
                        {popUpBoxTexts}
                    </Box>
                    : ""
                }
                {popUpBlockTexts}
                <a href="/privacy-policy" target="_blank">View Consumer Data Right policy</a>
            </DialogContent>
        </Dialog>

        <Footer/>
    </div>;
}

export default DataRequest;