import React from "react";
import {Box, Grid} from "@material-ui/core";
import LinkButton from "../../components/LinkButton";
import DashboardConfigurationCard from "./DashboardConfigurationCard";
import Configuration from "../models/Configuration";

interface AdminDashboardProps
{
    configurations: Array<Configuration>;
    basePath: string;
}

const ConfigurationDashboard = (props: AdminDashboardProps) => {
    return <>
        <h3 className="font-weight-normal">To get started, please create a new configuration.</h3>

        <Box mt={6} mb={2}>
            <h4>Your configurations</h4>
        </Box>
        <Grid container spacing={4}>
            {props.configurations.map((item, i) => (
                <Grid item xs={12} sm={6} md={4}>
                    <DashboardConfigurationCard 
                        key={i} {...item} 
                        basePath={props.basePath}
                    />
                </Grid>
            ))}
        </Grid>

        <Box mt={7}>
            <p><LinkButton variant={"contained"} color={"secondary"} targetlocation={props.basePath+ "/configuration/create"}>Create
                configuration</LinkButton></p>
            <p>For help or support, please contact <a href="mailto:support@illion.com.au">[placeholder]</a>.</p>
        </Box>
    </>
}

export default ConfigurationDashboard;