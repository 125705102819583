import React, { PropsWithChildren, ReactElement } from "react";
import { Box } from "@material-ui/core";

interface MessageBoxProps {
    icon: ReactElement;
    background: "red" | "green" | "grey";
    className?: string;
}

const MessageBox = (props: PropsWithChildren<MessageBoxProps>) => {
    return <Box className={`message-box ${props.background} ${props.className || ""}`}>
        <div id="icon">{props.icon}</div>
        {props.children}
    </Box>
}

export default MessageBox;