import React, {PropsWithChildren, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleUp, faAngleDown} from "@fortawesome/free-solid-svg-icons";
import {Typography} from "@material-ui/core";
import HighlightBlock from "./HighlightBlock";
import SwitchWithLabel from "./SwitchWithLabel";

interface DataRequestCardProps
{
    clusterName: string,
    purposeInformation?: string | JSX.Element,
    clusterPermissionNames?: Array<string>,
    onSelectionChange?: (newState: boolean) => void,
    isSelected: boolean;
    isMandatory?: boolean;
}

const DataClusterCard = (props: PropsWithChildren<DataRequestCardProps>) => {
    const handleChange = (checked: boolean) => {
        if (props.onSelectionChange) {
            props.onSelectionChange(checked);
        }
    }

    return <HighlightBlock className="data-request-card">
        <label>
            <SwitchWithLabel checked={props.isSelected} onChange={handleChange}>
                <Typography color={"secondary"} className="typo-display">
                    <h5>{props.clusterName}</h5>
                    {(props.isMandatory) &&
                        <p className="mandatory-field-indicator">
                            &nbsp;*
                        </p>
                    }
                </Typography>
            </SwitchWithLabel>
        </label>
        {(props.purposeInformation) &&
            <>
                <h6>Why we need it</h6>
                <p>{props.purposeInformation}</p>
            </>
        }
        {props.children}
        {(props.clusterPermissionNames && props.clusterPermissionNames.length > 0) &&
            <ClusterPermissionNames names={props.clusterPermissionNames}/>
        }
    </HighlightBlock>;
}

interface FullDataListToggleProps
{
    names: Array<string>;
}

const ClusterPermissionNames = ({names}: FullDataListToggleProps) => {
    const [isOpen, setState] = useState(false);

    const toggle = () => {
        const newState = !isOpen;
        setState(newState);
    }

    return <div className={"data-list-container"}>
        <Typography color={"secondary"} className={"data-list-toggle"} onClick={() => toggle()}>
            {(isOpen)
                ? <>Hide full data list <FontAwesomeIcon icon={faAngleUp} size={"lg"}/></>
                : <>Show full data list <FontAwesomeIcon icon={faAngleDown} size={"lg"}/></>
            }
        </Typography>
        <ul className={"data-list" + ((isOpen) ? " visible" : " hidden")}>
            {names.map(itemText => <li>{itemText}</li>)}
        </ul>
    </div>
}

export default DataClusterCard;
