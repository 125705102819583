import React, { PropsWithChildren } from "react";
import { Button, ButtonProps } from '@material-ui/core';
import {Link} from "react-router-dom";

interface LinkButtonProps extends ButtonProps {
    to: string;
};

const LinkButton = (props: PropsWithChildren<LinkButtonProps>) => {
    return <Button {...props}>
        <Link to={props.to}>{props.children}</Link>
    </Button>
}

export default LinkButton;


