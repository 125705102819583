import React from "react";
import ImageOutlinedIcon from '@material-ui/icons/ImageOutlined';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';

interface FileItemProps {
    type: "image" | "generic";
    name?: string;
}

const FileItem = (props: FileItemProps) => {
    return <div className={"file-item"}>
        {props.type === "image" ? <ImageOutlinedIcon/> : <InsertDriveFileOutlinedIcon/>}
        {props.name && <span>{props.name}</span>}
    </div>;
}

export default FileItem;
