import React, { PropsWithChildren } from "react";
import { Container } from "@material-ui/core";

interface PrimaryBlockProps {
}

const PrimaryBlock = (props: PropsWithChildren<PrimaryBlockProps>) => {
    return <div className="primary-block">
        <Container>
            <>
                {props.children}
            </>
        </Container>
    </div>
}

export default PrimaryBlock;
