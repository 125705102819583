import React, { useState } from "react";
import SecondaryBlock from "../components/SecondaryBlock";
import { Grid, Button, Breadcrumbs, Box, Container, Accordion, AccordionSummary, AccordionDetails, Typography, Link, makeStyles, Dialog, DialogContent } from "@material-ui/core";
import KeyboardArrowDownRoundedIcon from "@material-ui/icons/KeyboardArrowDownRounded";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faDownload, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useHistory, useParams } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import SwitchWithLabel from "../components/SwitchWithLabel";
import LinkButton from "../components/LinkButton";
import ButtonBlock from "../components/ButtonBlock";
import CloseIcon from '@material-ui/icons/Close';
import ModalPopUpData from "../models/ModalPopUpData";

const useStyles = makeStyles((theme) => ({
	consentDetailsHeader: {
		borderBottom: `1px dashed ${theme.palette.secondary.main}`
	},
	styledAccordion: {
		backgroundColor: theme.palette.info.main
	}
}));

interface ConsentDetailsProps {
	dashboardPath: string,
	onDeleteOptionChange: (newOption: boolean) => void;
    currentDeleteOption: boolean | undefined;
}

const ConsentDetails = (props: ConsentDetailsProps) => {
	const { id } = useParams<{ id: any }>();

	var newDeleteOption = false;
    if(props.currentDeleteOption){
        newDeleteOption = props.currentDeleteOption;
    }
	const [deleteDataOption, setDeleteDataOption] = useState(newDeleteOption);

	const modalPopupsData = [  
		{
			popUpHeading: "Supporting third parties",
			popUpGeneralText:[
				{
					content: "Supporting third parties are companies that work with illion to help you lorem ipsum dolor sit amet, consectetur adipiscing elit.",
				},
				{
					content: "They can access the data you have agreed to share with illion, but it can only be used for the purpose(s) you consented."
				}
			],
			popupBoxContents:[
				{
					heading:"Lorem Ipsum Business",
					content:"Curabitur vehicula, massa non tincidunt pellentesque, elit quam porta est, auctor vehicula eros nisl ut nisi. Aenean fringilla leo at porta venenatis."
				},
				{
					heading:"Lorem Ipsum Business",
					content:"Curabitur vehicula, massa non tincidunt pellentesque, elit quam porta est, auctor vehicula eros nisl ut nisi. Aenean fringilla leo at porta venenatis."
				}
			],
			popupBlockContents:[
				{
					heading: "Want more information?",
					content: [
						{
							text : "Refer to our Consumer Data Right policy for more information on our supporting third parties."
						},
						{
							text : "You can also request more information related to supporting third parties from us."
						}
					]
					
				}
			],
		},
        {
            popUpHeading: "De-identification of data",
            popUpGeneralText: [],
            popupBoxContents:[],
            popupBlockContents:[
                {
                    heading: "What does it mean to de-identify data?",
                    content: [
                        {
                            text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin elit felis, hendrerit eu mauris nec, iaculis placerat nisi. Nunc id tellus orci. Integer faucibus mauris sit amet ipsum accumsan consequat."
                        }
                    ]
                },
                {
                    heading: "How will we de-identify your data?",
                    content: [
                        {
                            text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin elit felis, hendrerit eu mauris nec, iaculis placerat nisi. Nunc id tellus orci. Integer faucibus mauris sit amet ipsum accumsan consequat."
                        }
                    ]
                },
                {
                    heading: "How will we use your de-identified data?",
                    content: [
                        {
                            text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin elit felis, hendrerit eu mauris nec, iaculis placerat nisi. Nunc id tellus orci. Integer faucibus mauris sit amet ipsum accumsan consequat."
                        }
                    ]
                },
                {
                    heading: "Want more information?",
                    content: [
                        {
                            text: "Refer to our Consumer Data Right policy for more information on data de-identification."
                        }
                    ]
                }
                
            ],
        },
        {
            popUpHeading: "Data deletion",
            popUpGeneralText: [],
            popupBoxContents:[],
            popupBlockContents:[
                {
                    heading: "What does it mean to delete data?",
                    content: [
                        {
                            text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin elit felis, hendrerit eu mauris nec, iaculis placerat nisi. Nunc id tellus orci. Integer faucibus mauris sit amet ipsum accumsan consequat. "
                        }
                    ]
                },
                {
                    heading: "When will you delete my data?",
                    content: [
                        {
                            text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin elit felis, hendrerit eu mauris nec, iaculis placerat nisi. Nunc id tellus orci. Integer faucibus mauris sit amet ipsum accumsan consequat. "
                        }
                    ]
                },
                {
                    heading: "How will you delete my data?",
                    content: [
                        {
                            text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin elit felis, hendrerit eu mauris nec, iaculis placerat nisi. Nunc id tellus orci. Integer faucibus mauris sit amet ipsum accumsan consequat. "
                        }
                    ]
                },
                {
                    heading: "What about supporting third parties?",
                    content: [
                        {
                            text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin elit felis, hendrerit eu mauris nec, iaculis placerat nisi. Nunc id tellus orci. Integer faucibus mauris sit amet ipsum accumsan consequat. "
                        }
                    ]
                },
                {
                    heading: "Want more information?",
                    content: [
                        {
                            text: "Refer to our Consumer Data Right policy for more information on data de-identification. "
                        }
                    ]
                }
            ],
        }
    ];


	const history = useHistory();

	const [open, setOpen] = React.useState(false);
	const [currentPopupModalData, setCurrentPopupModalData] = React.useState<ModalPopUpData>();

	const openPopup = (modalPopUpData: ModalPopUpData) => {
        setOpen(true);
        setCurrentPopupModalData(modalPopUpData);
    };

	const closePopup = () => {
        setOpen(false);
    };

	const handleSave = () => {
		history.push(props.dashboardPath);
	};

	const providerPopup = () => {
		const providerPopUpDatas = modalPopupsData[0];
        openPopup(providerPopUpDatas);
	}
	
	const deIdentifyPopup = () => {
		const identifyPopUpDatas = modalPopupsData[1];
        openPopup(identifyPopUpDatas);
	}

	const deletePopup = () => {
		const deletePopUpDatas = modalPopupsData[2];
        openPopup(deletePopUpDatas);
	}

	const popUpGeneralText = currentPopupModalData?.popUpGeneralText.map((item) =>
            <p>{item.content}</p>
    );
    
    const popUpBoxText = currentPopupModalData?.popupBoxContents.map((item) =>
        <>
            <h6>{item.heading}</h6>
            <p>{item.content}</p>
        </>
    );

    const popUpBlockText = currentPopupModalData?.popupBlockContents.map(item =>{
        return (
        <>
            <h6>{item.heading}</h6>
			<br />
            {
                item.content?.map(word =>{
                    return (
                        <p>{word.text}</p>
                    )
                })
            }
			<br />
        </>
        )
    });

	const style = useStyles();

	const handleDataDeleteOption = (currentOption: boolean) => {
        setDeleteDataOption(currentOption);
        if(props.onDeleteOptionChange){
            props.onDeleteOptionChange(currentOption);
        }   
	}
	
	const handleGoBack = () => {
		history.push(props.dashboardPath);
	};

	return <>
		<Header />

		<Container maxWidth={"lg"}>
			<Breadcrumbs aria-label="breadcrumb">
				<Link color="inherit" href={props.dashboardPath}>Dashboard</Link>
				<span>Commonwealth Bank of Australia</span>
			</Breadcrumbs>

			<div className={`consent-details-header ${style.consentDetailsHeader}`}>
				<Grid container spacing={3} className={"institution-card"}>
					<Grid item xs={12} md={9}>
						<Grid container>
							<Grid item xs={"auto"} sm={1}><img src="/images/data-holder-logos/commbank-logo-round.png" alt="" /></Grid>
							<Grid item xs={12} sm={11}>
								<h2>Commonwealth Bank of Australia</h2>
								<div id="card-misc">
									<span>Product: Money Simple</span>
									<span className={"status-active"}><FontAwesomeIcon icon={faCircle} /> Active</span>
									<Typography component={"span"} color={"secondary"}><FontAwesomeIcon icon={faDownload} /> <a href="/sample-url">Download confirmation of consent</a></Typography>
								</div>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12} md={3} className={"text-align-right"}>
						<LinkButton targetlocation={props.dashboardPath + `/consent-details/${id}/withdraw`} variant={"contained"} color={"secondary"}>Stop sharing</LinkButton>
					</Grid>
				</Grid>
			</div>

			<Grid container spacing={5} className={"data-collection-details"}>
				{/* Column 1 */}
				<Grid item xs={12} md={6}>
					<Box mb={3}>
						<Typography color={"secondary"}><h3 className="font-weight-normal">Data we are collecting</h3></Typography>
					</Box>

					<Accordion className={`styled-accordion ${style.styledAccordion}`} defaultExpanded>
						<Typography color={"secondary"}>
							<AccordionSummary>Direct debits and scheduled payments</AccordionSummary>
						</Typography>
						<AccordionDetails>
							<Box>
								<h6>Why we need it</h6>
								<p>This data allows us to identify how much money you are spending.</p>
							</Box>
							<Box mt={2}>
								<h6>What we are collecting</h6>
								<ul>
									<li>Incoming and outgoing transactions</li>
									<li>Amounts</li>
									<li>Dates</li>
									<li>Descriptions of transactions</li>
									<li>Who you’ve sent money to and received money from (e.g. their name)</li>
								</ul>
							</Box>
							<Box mt={2}>
								<h6>Historical data we’ve collected</h6>
								<p>We have collected transaction data that may date back to 1st January 2017.</p>
							</Box>
							<Box mt={2}>
								<h6>When we’ve collected your data</h6>
								<p>We first collected your transaction details from Commonwealth Bank of Australia on 3 July 2020.</p>
								<p>We will continue to collect this every time you use Money Simple until 2 July 2021.</p>
							</Box>
						</AccordionDetails>
					</Accordion>

					<Accordion className={`styled-accordion ${style.styledAccordion}`}>
						<Typography color={"secondary"}>
							<AccordionSummary>Transaction details</AccordionSummary>
						</Typography>
						<AccordionDetails>
							<Box>
								<h6>Why we need it</h6>
								<p>This data allows us to identify how much money you are spending.</p>
							</Box>
							<Box mt={2}>
								<h6>What we are collecting</h6>
								<ul>
									<li>Incoming and outgoing transactions</li>
									<li>Amounts</li>
									<li>Dates</li>
									<li>Descriptions of transactions</li>
									<li>Who you’ve sent money to and received money from (e.g. their name)</li>
								</ul>
							</Box>
							<Box mt={2}>
								<h6>Historical data we’ve collected</h6>
								<p>We have collected transaction data that may date back to 1st January 2017.</p>
							</Box>
							<Box mt={2}>
								<h6>When we’ve collected your data</h6>
								<p>We first collected your transaction details from Commonwealth Bank of Australia on 3 July 2020.</p>
								<p>We will continue to collect this every time you use Money Simple until 2 July 2021.</p>
							</Box>
						</AccordionDetails>
					</Accordion>
				</Grid>
				{/* End of Column 1 */}

				{/* Column 2 */}
				<Grid item xs={12} md={6}>
					<Accordion className={"simple-accordion"} defaultExpanded>
						<Typography color={"secondary"}>
							<AccordionSummary expandIcon={<KeyboardArrowDownRoundedIcon color={"secondary"} />}>Additional uses of your data</AccordionSummary>
						</Typography>
						<AccordionDetails>
							<p>You have agreed to let us do the following:</p>
							<ul>
								<li>Use your data to suggest ways you can save money</li>
								<li>Analyse your spending behaviour to send you additional offers</li>
							</ul>
							<h6>Supporting third parties</h6>
							<p>Supporting third parties will help provide this service. They will be bound by the same terms of this arrangement.</p>
							<p><a href="/#" onClick={(e) => {e.preventDefault(); providerPopup();}}>See list of providers</a></p>
						</AccordionDetails>
					</Accordion>

					<Accordion className={"simple-accordion"}>
						<Typography color={"secondary"}>
							<AccordionSummary expandIcon={<KeyboardArrowDownRoundedIcon color={"secondary"} />}>Key dates</AccordionSummary>
						</Typography>
						<AccordionDetails>
							<h6>When you gave consent</h6>
							<p>3 July 2020</p>

							<h6>When your consent will expire</h6>
							<p>3 July 2021</p>

							<h6>How often we access your data</h6>
							<p>We access your data every time you log into Money Simple during the period of 3 July 2020 to 2 July 2021.</p>
						</AccordionDetails>
					</Accordion>

					<Accordion className={"simple-accordion"}>
						<Typography color={"secondary"}>
							<AccordionSummary expandIcon={<KeyboardArrowDownRoundedIcon color={"secondary"} />}>Your data when we no longer require it</AccordionSummary>
						</Typography>
						<AccordionDetails>
							<Box>
								<p>We will de-identify your data when we no longer need it to help you track your spending, and there Is no legal obligation to retain it.</p>
								<p><a href="/#" onClick={(e) => {e.preventDefault(); deIdentifyPopup();}}>How and why we de-identify your data</a></p>
							</Box>
							<Box mt={2}>
								<SwitchWithLabel checked={deleteDataOption} onChange={value => { handleDataDeleteOption(value); }}>
									<h6>Delete my data instead</h6>
								</SwitchWithLabel>
								<p>If you don’t do this by 2 July 2021, your data will be de-identified.</p>
								<p><a href="/#" onClick={(e) => {e.preventDefault(); deletePopup();}}>See how we delete your data</a></p>
							</Box>
						</AccordionDetails>
					</Accordion>
				</Grid>
				{/* End of Column 2 */}

				<Container maxWidth={"lg"}>
					<Grid container spacing={3}>
						<Grid item xs={12} sm={3} className={"align-self-center"}>
							<Link color={"secondary"} className={"go-back-link"} href="/#" onClick={(e : any) => {e.preventDefault(); handleGoBack(); }}><FontAwesomeIcon size={"sm"} icon={faArrowLeft} />Back</Link>
						</Grid>
						<Grid item xs={12} sm={9}>
							<ButtonBlock className={"text-align-right mt-0"}>
								<LinkButton targetlocation={props.dashboardPath} variant={"outlined"} color={"secondary"}>Cancel</LinkButton>
								<Button onClick={handleSave} variant={"contained"} color={"secondary"}>Save</Button>
							</ButtonBlock>
						</Grid>
					</Grid>
				</Container>
			</Grid>
		</Container>

		<SecondaryBlock>
			Please read our <a href="/privacy-policy">Consumer Data Right policy</a> for some information on how Money Simple handles your data.
		</SecondaryBlock>

		{/* Information popup */}
        <Dialog
            id={"popup-3"}
            maxWidth={"sm"}
            open={open}
        >
            <CloseIcon 
               onClick={closePopup}
               className="close-popup-icon" 
            />
            <DialogContent>
                <h2>{currentPopupModalData?.popUpHeading}</h2>

                {popUpGeneralText}

                {(popUpBoxText?.length)?
                    <Box my={3} p={3} className={"background-grey"}>
                        {popUpBoxText}
                    </Box>
                    : ""
                }
                {popUpBlockText}
                <a href="/privacy-policy" target="_blank">View Consumer Data Right policy</a>
            </DialogContent>
        </Dialog>

		<Footer />
	</>;
}

export default ConsentDetails;