import React from "react";
import LinkButton from "../components/LinkButton";
import ButtonBlock from "../components/ButtonBlock";
import MessageBox from "../components/MessageBox";
import PrimaryBlock from "../components/PrimaryBlock";
import DataHolder from "../models/DataHolder";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { Container, Typography, Box } from "@material-ui/core";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import Header from "../components/Header";
import Footer from "../components/Footer";

interface SuccessProps {
    currentDataHolderSelection : DataHolder
	dashboardPath: string;
    connectAnotherDataHolderPath: string;
}

const Success = (props : SuccessProps) => {
	return <>
		<Header />

		<PrimaryBlock>
			<Container maxWidth={"sm"} className="text-align-center">
				<MessageBox icon={<CheckCircleOutlineIcon />} background={"green"}>
					<p>You have successfully consented to share your {props.currentDataHolderSelection.title}'s data with us. You can see a summary of this sharing arrangement in your Data Sharing Management Dashboard.</p>
					<Typography color={"secondary"}>
						<FontAwesomeIcon icon={faDownload} color={"secondary"} />&nbsp; <a href="/sample-url">Download confirmation PDF</a>
					</Typography>
				</MessageBox>

				<Box my={5}>
					<ButtonBlock className="alignItemsCenter">
						<LinkButton targetlocation={props.dashboardPath} variant={"contained"} color={"secondary"}>Visit Dashboard</LinkButton>
					</ButtonBlock>
				</Box>

				<Typography color={"secondary"}>
					<FontAwesomeIcon icon={faPlusCircle} />&nbsp; <a href={props.connectAnotherDataHolderPath}>Grant consent to another institution</a>
				</Typography>
			</Container>
		</PrimaryBlock>

		<Footer />
	</>;
}

Success.defaultProps = {
	currentDataHolderSelection : {id:'',logo:'',title:'Bank Name'}
}

export default Success;