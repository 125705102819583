import React from "react";
import {Grid , Link} from '@material-ui/core';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

import PrimaryBlock from "../components/PrimaryBlock";
import SecondaryBlock from "../components/SecondaryBlock";

import AccreditationBadge from "../components/AccreditationBadge";
import LinkButton from "../components/LinkButton";
import Card from "../components/Card";
import { Dialog, DialogContent, DialogActions, Button, Typography } from "@material-ui/core";

import { useHistory } from 'react-router-dom';
import Header from "../components/Header";
import Footer from "../components/Footer";

interface CDRValuePropositionProps {
    prev: string;
    next: string;
}

const CDRValueProposition = (props: CDRValuePropositionProps) => {

    const history = useHistory();

    const [open, setOpen] = React.useState(false);

    const openPopup = () => {
        setOpen(true);
    };

    const closePopup = () => {
        setOpen(false);
    };

    const cancelProcess = () => {
        if (history.length)
            history.goBack();
        else
            history.push(props.prev || "/");
    };

    const handleGoBack = () => {
		history.goBack();
	};

    return <div className={"cdr-value-proposition"}>
        <Header />

        <PrimaryBlock>
            <Grid container spacing={3}>
				<Grid item xs={12}>
					<Link color={"secondary"} className={"go-back-link"} href="/#" onClick={(e : any) => {e.preventDefault(); handleGoBack(); }}><FontAwesomeIcon size={"sm"} icon={faArrowLeft} />Back</Link>
				</Grid>
			</Grid>
            <Grid id={"primary-content-1"} container spacing={3}>
                <Grid item xs={12} sm={5} md={4}>
                    <div className={"hero-image"}>
                        <img className="mw-100" src="/images/hero-image-2.png" alt={""} />
                    </div>
                </Grid>
                <Grid item xs={12} sm={7} md={8} className="align-self-center">
                    <h3>Please provide your information</h3>
                    <p>To see where your money goes, and identify strategies for your personal financial situation, we need some information from you.</p>
                    <p>We take your security very seriously. The credentials you provide are used solely to retrieve data on your behalf. We take your security very seriously. The credentials you provide are used solely to retrieve data on your behalf.</p>
                </Grid>
            </Grid>

            <Grid id={"primary-content-2"} container spacing={6}>
                <Grid item md={6} sm={12}>
                    <h3>Share data with the consumer data right</h3>
                    <p>You can connect us with your bank(s) via the Consumer Data Right. You can connect us with your bank(s) via the Consumer Data Right.</p>
                    <AccreditationBadge
                        companyName={"illion Australia Pty Ltd"}
                        dataRecepientId={"123456"}
                        logoUrl={"/images/data-holder-logos/consumer-data-right.png"}
                    />
                    <p>We are Accredited Consumer Data right Recipient, meaning we are bound by rules set by the Australian Government on how we can handle your data.</p>
                    {/* eslint-disable-next-line */}
                    <p>To learn more, visit <a href="https://www.illion.com.au/about-us/" rel="noopener" target="_blank">About us</a></p>
                </Grid>
                <Grid item md={6} sm={12}>
                    <h3>What you should know</h3>
                    <ul>
                        <li>We don't share or use your data without your consent</li>
                        <li>You can easily stop sharing your bank data with us at anytime</li>
                        <li>You can choose to delete shared data that we no longer need</li>
                    </ul>
                    <p>Find out more in our <a href="/privacy-policy" target="_blank" rel="noopener noreferrer">Consumer Data Right policy</a></p>
                </Grid>
            </Grid>
        </PrimaryBlock>

        <SecondaryBlock
            header={<>How to share your data</>}
            ctaButtons={[
                <Button onClick={openPopup} variant={"outlined"} color={"secondary"}>Cancel</Button>,
                <LinkButton targetlocation={props.next} variant={'contained'} color={"secondary"}>Start</LinkButton>
            ]}
        >
            <p>To share your data, we will ask you to:</p>
            <Grid container spacing={3}>
                <Grid item sm={4} xs={12}>
                    <Card image="/images/icon-4.png">
                        <Typography component={"span"} color={"secondary"}><b>1.</b></Typography> <b>Consent</b> to share your data with us
                    </Card>
                </Grid>
                <Grid item sm={4} xs={12}>
                    <Card image="/images/icon-5.png">
                        <Typography component={"span"} color={"secondary"}><b>2.</b></Typography> <b>Connect</b> to your bank (we won't see this information)
                    </Card>
                </Grid>
                <Grid item sm={4} xs={12}>
                    <Card image="/images/icon-6.png">
                        <Typography component={"span"} color={"secondary"}><b>3.</b></Typography> <b>Confirm</b> with your bank that you'll share data with us
                    </Card>
                </Grid>
            </Grid>
            <p>This should only take a few minutes. We will walk you through each step.</p>
        </SecondaryBlock>

        {/* Cancellation popup */}
        <Dialog
            maxWidth={"sm"}
            open={open}
            onClose={closePopup}
        >
            <DialogContent>
                <h2>Cancel process?</h2>
                <p>By not sharing your bank data, we will not be able to automatically collect this information. However, you can still provide this information manually when setting up Money Simple.</p>
                <p>Are you sure you would like to cancel this process?</p>
            </DialogContent>
            <DialogActions>
                <Button onClick={cancelProcess} variant={"contained"} color={"secondary"}>Yes</Button>
                <Button onClick={closePopup} variant={"outlined"} color={"secondary"}>No</Button>
            </DialogActions>
        </Dialog>

        <Footer />
    </div>;
}

export default CDRValueProposition;