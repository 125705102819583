import React, { PropsWithChildren } from "react";
import { useHistory } from "react-router-dom";
import { Button, ButtonProps } from '@material-ui/core';

interface LinkButtonProps extends ButtonProps {
    targetlocation: string;
};

const LinkButton = (props: PropsWithChildren<LinkButtonProps>) => {
    const history = useHistory();

    const handleClick = () => {
        history.push(props.targetlocation);
    }

    return <Button {...props} onClick={() => handleClick()}>
        {props.children}
    </Button>

}

export default LinkButton;


