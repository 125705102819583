import React from "react";
import { useHistory } from "react-router-dom";
import { Grid, Link, Hidden, Typography } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faChevronRight, faDownload } from "@fortawesome/free-solid-svg-icons";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import MessageBox from "./MessageBox";
import InstitutionListItemData from "../models/InstitutionListItemData";

interface InstitutionListItemProps extends InstitutionListItemData {
	consentDetailsPath: string;
}

const InstitutionListItem = (props: InstitutionListItemProps) => {
	const history = useHistory();

	const handleViewDetails = () => {
		history.push(props.consentDetailsPath + `/${props.id}`);
	};

	return <Grid container className={"institution-list-item"}>
		<Grid item xs={"auto"} md={1}><img src={props.logoImageUrl} alt="" /></Grid>
		<Grid item xs={10} md={3}>{props.institutionName}</Grid>
		<Grid item xs={6} md={2}><span className={`status-${props.status}`}><FontAwesomeIcon icon={faCircle} /> {props.status}</span></Grid>
		<Grid item xs={6} md={2}>{props.categories.join(", ")}</Grid>
		<Grid item xs={6} md={2}>{props.date}</Grid>
		<Grid item xs={6} md={2}><Link color={"secondary"} href="/#" onClick={(e : any) => {e.preventDefault(); handleViewDetails(); }}><b><u>View details</u> <FontAwesomeIcon icon={faChevronRight} /></b></Link></Grid>

		{props.message && <>
			<Hidden xsDown><Grid item md={1} className={"pt-0"}></Grid></Hidden>
			<Grid item xs={12} md={11} className={"pt-0"}>
				<MessageBox icon={props.message.type === "success" ? <CheckCircleOutlineIcon /> : <ErrorOutlineOutlinedIcon />} background={"green"}>
					{props.message.content}
					{props.message.links.map((link, i) =>
						<Typography key={i} color={"secondary"}>
							{link.icon === "download" && <><FontAwesomeIcon icon={faDownload} /> &nbsp;</>}
							<a href={link.url}>{link.text}</a>
						</Typography>
					)}
				</MessageBox>
			</Grid>
		</>}
	</Grid>
}

export default InstitutionListItem;


