import React from "react";
import { useHistory } from "react-router-dom";
import SecondaryBlock from "../components/SecondaryBlock";
import { Grid, Breadcrumbs, Box, Container, Accordion, AccordionSummary, AccordionDetails, Typography, Link, makeStyles } from "@material-ui/core";
import MessageBox from "../components/MessageBox";
import KeyboardArrowDownRoundedIcon from "@material-ui/icons/KeyboardArrowDownRounded";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faDownload, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import Header from "../components/Header";
import Footer from "../components/Footer";

const useStyles = makeStyles((theme) => ({
	consentDetailsHeader: {
		borderBottom: `1px dashed ${theme.palette.secondary.main}`
	},
	styledAccordion: {
		backgroundColor: theme.palette.info.main
	}
}));

interface WithdrawConsentSuccessProps {
	dashboardPath: string,
}

const WithdrawConsentSuccess = (props: WithdrawConsentSuccessProps) => {
	// const { id } = useParams<{ id: any }>();

	const style = useStyles();

	const history = useHistory();
	const handleGoBack = () => {
		history.push(props.dashboardPath);
	};

	return <>
		<Header />

		<Container maxWidth={"lg"}>
			<Breadcrumbs aria-label="breadcrumb">
				<Link color="inherit" href={props.dashboardPath}>Dashboard</Link>
				<span>Commonwealth Bank of Australia</span>
			</Breadcrumbs>

			<div className={`consent-details-header ${style.consentDetailsHeader}`}>
				<Grid container spacing={3} className={"institution-card"}>
					<Grid item xs={12} md={9}>
						<Grid container>
							<Grid item xs={"auto"} sm={1}><img src="/images/data-holder-logos/commbank-logo-round.png" alt="" /></Grid>
							<Grid item xs={12} sm={11}>
								<h2>Commonwealth Bank of Australia</h2>
								<div id="card-misc">
									<span>Product: Money Simple</span>
									<span className={"status-inactive"}><FontAwesomeIcon icon={faCircle} /> Inactive</span>
									<Typography component={"span"} color={"secondary"}><FontAwesomeIcon icon={faDownload} /> <a href="/#" onClick={(e) => {e.preventDefault();}}>Download confirmation of consent withdrawal</a></Typography>
								</div>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</div>

            <MessageBox icon={<CheckCircleOutlineIcon />} background={"green"}>
                    <p className = {"withdraw-success-msg-margin"}>You have successfully stopped sharing the data below with us. We will no longer have access to this data. This information has been archived.</p>
                    <Typography component={'div'} color={"secondary"}>
						<FontAwesomeIcon icon={faDownload} color={"secondary"} />&nbsp; <a href="/#" onClick={(e) => {e.preventDefault();}}>Download confirmation PDF</a>
					</Typography>
			</MessageBox>

			<Grid container spacing={5} className={"data-collection-details data-collection-details-padding"}>
                
				{/* Column 1 */}
				<Grid item xs={12} md={6}>
					<Box mb={3}>
						<Typography component={'div'} color={"secondary"}><h3 className="font-weight-normal">Data we’ve collected</h3></Typography>
					</Box>

					<Accordion className={`styled-accordion ${style.styledAccordion}`}>
						<Typography component={'div'} color={"secondary"}>
							<AccordionSummary>Direct debits and scheduled payments</AccordionSummary>
						</Typography>
						<AccordionDetails>
							<Box>
								<h6>Why we need it</h6>
								<p>This data allows us to identify how much money you are spending.</p>
							</Box>
							<Box mt={2}>
								<h6>What we are collecting</h6>
								<ul>
									<li>Incoming and outgoing transactions</li>
									<li>Amounts</li>
									<li>Dates</li>
									<li>Descriptions of transactions</li>
									<li>Who you’ve sent money to and received money from (e.g. their name)</li>
								</ul>
							</Box>
							<Box mt={2}>
								<h6>Historical data we’ve collected</h6>
								<p>We have collected transaction data that may date back to 1st January 2017.</p>
							</Box>
							<Box mt={2}>
								<h6>When we’ve collected your data</h6>
								<p>We first collected your transaction details from Commonwealth Bank of Australia on 3 July 2020.</p>
								<p>We will continue to collect this every time you use Money Simple until 2 July 2021.</p>
							</Box>
						</AccordionDetails>
					</Accordion>

					<Accordion className={`styled-accordion ${style.styledAccordion}`}>
						<Typography component={'div'} color={"secondary"}>
							<AccordionSummary>Transaction details</AccordionSummary>
						</Typography>
						<AccordionDetails>
							<Box>
								<h6>Why we need it</h6>
								<p>This data allows us to identify how much money you are spending.</p>
							</Box>
							<Box mt={2}>
								<h6>What we are collecting</h6>
								<ul>
									<li>Incoming and outgoing transactions</li>
									<li>Amounts</li>
									<li>Dates</li>
									<li>Descriptions of transactions</li>
									<li>Who you’ve sent money to and received money from (e.g. their name)</li>
								</ul>
							</Box>
							<Box mt={2}>
								<h6>Historical data we’ve collected</h6>
								<p>We have collected transaction data that may date back to 1st January 2017.</p>
							</Box>
							<Box mt={2}>
								<h6>When we’ve collected your data</h6>
								<p>We first collected your transaction details from Commonwealth Bank of Australia on 3 July 2020.</p>
								<p>We will continue to collect this every time you use Money Simple until 2 July 2021.</p>
							</Box>
						</AccordionDetails>
					</Accordion>


                    <h6>What happened to your shared data?</h6>
                    <p>The data you have shared has been deleted. <a href="/#" onClick={(e) => {e.preventDefault();}}>Learn more</a></p>
				</Grid>
				{/* End of Column 1 */}

				{/* Column 2 */}
				<Grid item xs={12} md={6}>
					<Accordion className={"simple-accordion"} defaultExpanded>
						<Typography component={'div'} color={"secondary"}>
							<AccordionSummary expandIcon={<KeyboardArrowDownRoundedIcon color={"secondary"} />}>How your data was used</AccordionSummary>
						</Typography>
						<AccordionDetails>
							<h6>Purpose of data sharing</h6>
                            <p>We collected and used your data to help you track your budget.</p>

                            <h6>Sharing with third parties</h6>
                            <p>Supporting third parties no longer have access to your data. They were bound by the same terms of this arrangement.</p>
							
                            
                            <p><a href="/#" onClick={(e) => {e.preventDefault();}}>Learn more</a></p>
						</AccordionDetails>
					</Accordion>

					<Accordion className={"simple-accordion"} defaultExpanded>
						<Typography component={'div'} color={"secondary"}>
							<AccordionSummary expandIcon={<KeyboardArrowDownRoundedIcon color={"secondary"} />}>Key dates</AccordionSummary>
						</Typography>
						<AccordionDetails>
							<h6>When you gave consent</h6>
							<p>3 July 2020</p>

							<h6>When you cancelled your consent</h6>
							<p>3 July 2021</p>

                            <h6>Sharing period</h6>
							<p>3 July 2020 - 3 July 2021</p>

							<h6>How often we accessed your data</h6>
							<p>We accessed your data every time you used Money Simple.</p>
						</AccordionDetails>
					</Accordion>
				</Grid>
				{/* End of Column 2 */}

				<Container maxWidth={"lg"}>
					<Grid container spacing={3}>
						<Grid item xs={12} sm={3} className={"align-self-center"}>
							<Link color={"secondary"} className={"go-back-link"} href="/#" onClick={(e : any) => {e.preventDefault(); handleGoBack(); }}><FontAwesomeIcon size={"sm"} icon={faArrowLeft} />Back</Link>
						</Grid>
					</Grid>
				</Container>
			</Grid>
		</Container>

		<SecondaryBlock>
			Please read our <a href="/privacy-policy">Consumer Data Right policy</a> for some information on how Money Simple handles your data.
		</SecondaryBlock>

		<Footer />
	</>;
}

export default WithdrawConsentSuccess;