import React, { useRef , MutableRefObject, ChangeEvent } from "react";
import GeneralSettings from "../models/GeneralSettings";
import {Box, FormControl, Grid, MenuItem, Select, TextareaAutosize, TextField} from "@material-ui/core";
import LightTooltip from "./LightTooltip";
import InfoIcon from "@material-ui/icons/Info";
import FileItem from "./FileItem";
import {ColorPicker} from "material-ui-color";

interface EditGeneralSettingsProps
{
    generalSettings: GeneralSettings,
    onGeneralSettingsChange?: (newGeneralSettings: GeneralSettings) => void,
    configurationName: string,
    configurationDescription: string,
    onConfigurationNameChange?: (name: string) => void,
    onConfigurationDescriptionChange?: (description: string) => void,
}

const EditGeneralSettings = (props: EditGeneralSettingsProps) => {

    const cdrFileUploadInput = useRef() as MutableRefObject<HTMLInputElement>;

    const handleGeneralSettingsChange = (newGeneralSettings: GeneralSettings) => {
        props.onGeneralSettingsChange && props.onGeneralSettingsChange(newGeneralSettings);
    }

    const handleUploadClick = () => {
        cdrFileUploadInput.current.click();
    }

    const handleCapture = (event: ChangeEvent<HTMLInputElement>) => {
        var filename = "";
        if(event.target.files === null){
            filename = "No file found";
        }
        else{
            filename = event.target.files[0].name;
        }
        handleGeneralSettingsChange({
            ...props.generalSettings,
            cdrPolicyPDF: filename
        })
    }

    return <form className={"admin-form generalSettings-settings-form"} autoComplete={"off"}>
        <div className={"mt-50"}>
            <Box mb={2}>
                <h4>Configuration Details</h4>
            </Box>
            <Grid container className={"form-group"}>
                <Grid item xs={12} sm={4} md={3} className={"align-self-center"}>
                    <label>Name <LightTooltip title="Name of the configuration" placement="right-start"
                                              arrow><InfoIcon color={"secondary"}/></LightTooltip></label>
                </Grid>
                <Grid item xs={12} sm={7} md={5}>
                    <TextField 
                        required = {true}
                        variant="outlined" 
                        name="name" 
                        className="w-100"
                               value={props.configurationName}
                               onChange={(event) => {
                                   props.onConfigurationNameChange &&
                                   props.onConfigurationNameChange(event.target.value);
                               }}
                    />
                </Grid>
            </Grid>
            <Grid container className={"form-group"}>
                <Grid item xs={12} sm={4} md={3} className={"align-self-center"}>
                    <label>Description <LightTooltip title="Description of the configuration"
                                                     placement="right-start" arrow><InfoIcon
                        color={"secondary"}/></LightTooltip></label>
                </Grid>
                <Grid item xs={12} sm={7} md={5}>
                    <TextareaAutosize rowsMin={5} rowsMax={5} id="description" name="description"
                                      className="w-100" value={props.configurationDescription}
                               onChange={(event) => {
                                   props.onConfigurationDescriptionChange &&
                                   props.onConfigurationDescriptionChange(event.target.value);
                               }}
                    />
                </Grid>
            </Grid>
        </div>

        <div className={"mt-50"}>
            <Box mb={2}>
                <h4>Principal’s Details</h4>
            </Box>
            <Grid container className={"form-group"}>
                <Grid item xs={12} sm={4} md={3} className={"align-self-center"}>
                    <label>Name <LightTooltip title="Principal’s name" placement="right-start"
                                              arrow><InfoIcon color={"secondary"}/></LightTooltip></label>
                </Grid>
                <Grid item xs={12} sm={7} md={5}>
                    <TextField
                        disabled
                        required variant="outlined" 
                        name="name" 
                        className="w-100 disabled-text-field"
                        value={props.generalSettings.principalName}
                        onChange={(event) => {
                            handleGeneralSettingsChange({
                                ...props.generalSettings,
                                principalName: event.target.value,
                            })
                        }}
                    />
                </Grid>
            </Grid>
            <Grid container className={"form-group"}>
                <Grid item xs={12} sm={4} md={3} className={"align-self-center"}>
                    <label>Accreditation Number <LightTooltip title="Principal’s accreditation Number"
                                                              placement="right-start" arrow><InfoIcon
                        color={"secondary"}/></LightTooltip></label>
                </Grid>
                <Grid item xs={12} sm={7} md={5}>
                    <TextField 
                        required 
                        disabled
                        variant="outlined" 
                        name="accr-number" 
                        className="w-100 disabled-text-field"
                               value={props.generalSettings.accreditationNumber}
                               onChange={(event) => {
                                   handleGeneralSettingsChange({
                                       ...props.generalSettings,
                                       accreditationNumber: event.target.value,
                                   })
                               }}
                    />
                </Grid>
            </Grid>
            <Grid container className={"form-group"}>
                <Grid item xs={12} sm={4} md={3} className={"align-self-center"}>
                    <label>ADR Name <LightTooltip title="Principal’s ADR Name" placement="right-start"
                                                  arrow><InfoIcon color={"secondary"}/></LightTooltip></label>
                </Grid>
                <Grid item xs={12} sm={7} md={5}>
                    <TextField 
                        required 
                        disabled
                        variant="outlined" 
                        name="adr-name" 
                        className="w-100 disabled-text-field"
                               value={props.generalSettings.adrName}
                               onChange={(event) => {
                                   handleGeneralSettingsChange({
                                       ...props.generalSettings,
                                       adrName: event.target.value,
                                   })
                               }}
                    />
                </Grid>
            </Grid>
        </div>

        <div className={"mt-50"}>
            <Box mb={2}>
                <h4>Legal Details</h4>
            </Box>
            <Grid container className={"form-group"}>
                <Grid item xs={12} sm={4} md={3} className={"align-self-center"}>
                    <label>CDR Policy URL <LightTooltip title="Consumer data right Policy URL" placement="right-start"
                                                        arrow><InfoIcon color={"secondary"}/></LightTooltip></label>
                </Grid>
                <Grid item xs={12} sm={7} md={5}>
                    <TextField 
                        required 
                        variant="outlined" 
                        name="cdr-policy-url" 
                        className="w-100"
                        value={props.generalSettings.cdrPolicyURL}
                        placeholder="CDR policy url"
                        onChange={(event) => {
                            handleGeneralSettingsChange({
                                ...props.generalSettings,
                                cdrPolicyURL: event.target.value as string
                            })
                        }}
                    />
                </Grid>
            </Grid>
            <Grid container className={"form-group"}>
                <Grid item xs={12} sm={4} md={3} className={"align-self-center"}>
                    <label>CDR Policy PDF <LightTooltip title="Consumer data right Policy PDF" placement="right-start"
                                                        arrow><InfoIcon color={"secondary"}/></LightTooltip></label>
                </Grid>
                <Grid item xs={12} sm={7} md={5} className = {"grid-file-input"}>
                    <div>
                        <Box className={"details-box"}>
                            <FileItem type={"generic"} name={props.generalSettings.cdrPolicyPDF}/>
                        </Box>       
                        <div className="date">Uploaded 20/10/2020</div>
                    </div>
                    

                    <Box mt={2} className = {"input-file-box"}>
                        <input
                            color="primary"
                            accept="application/pdf"
                            type="file"
                            onChange={handleCapture}
                            id="cdr-policy-upload"
                            style={{ display: 'none', }}
                            ref={cdrFileUploadInput}
                        />
                        <a href={"/#"} 
                            onClick={(e) => {e.preventDefault(); handleUploadClick();}}
                        >
                            Upload new
                        </a>
                    </Box>
                </Grid>
            </Grid>
        </div>

        <div className={"mt-50"}>
            <Box mb={2}>
                <h4>General Styling</h4>
            </Box>
            <Grid container className={"form-group"}>
                <Grid item xs={12} sm={4} md={3} className={"align-self-center"}>
                    <label>Font family <LightTooltip title="General styling font family" placement="right-start"
                                                     arrow><InfoIcon color={"secondary"}/></LightTooltip></label>
                </Grid>
                <Grid item xs={12} sm={7} md={5}>
                    <FormControl variant="outlined" className="w-100">
                        <Select
                            name="font-family"
                            value={props.generalSettings.fontFamily}
                            onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                                handleGeneralSettingsChange({
                                    ...props.generalSettings,
                                    fontFamily: event.target.value as string
                                })
                            }}
                        >
                            <MenuItem value={""}>- Choose a font family -</MenuItem>
                            <MenuItem value={"Nunito Sans"}>Nunito Sans</MenuItem>
                            <MenuItem value={"Helvetica"}>Helvetica</MenuItem>
                            <MenuItem value={"'Open Sans'"}>Open Sans</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container className={"form-group"}>
                <Grid item xs={12} sm={4} md={3} className={"align-self-center"}>
                    <label>Text colour <LightTooltip title="General styling text colour" placement="right-start"
                                                     arrow><InfoIcon color={"secondary"}/></LightTooltip></label>
                </Grid>
                <Grid item xs={12} sm={7} md={5}>
                    <ColorPicker
                        value={props.generalSettings.textColor}
                        onChange={color => {
                            var newColor = '#'+color.hex;
                            if(typeof color === "string"){
                                newColor = color;
                            }
                            handleGeneralSettingsChange({...props.generalSettings, textColor: newColor })
                        }}
                    />
                </Grid>
            </Grid>
            <Grid container className={"form-group"}>
                <Grid item xs={12} sm={4} md={3} className={"align-self-center"}>
                    <label>Primary colour <LightTooltip title="General styling primary colour" placement="right-start"
                                                        arrow><InfoIcon color={"secondary"}/></LightTooltip></label>
                </Grid>
                <Grid item xs={12} sm={7} md={5}>
                    <ColorPicker
                        value={props.generalSettings.primaryColor}
                        onChange={color => {
                            var newColor = '#'+color.hex;
                            if(typeof color === "string"){
                                newColor = color;
                            }
                            handleGeneralSettingsChange({...props.generalSettings, primaryColor: newColor })
                        }}
                    />
                </Grid>
            </Grid>
            <Grid container className={"form-group"}>
                <Grid item xs={12} sm={4} md={3} className={"align-self-center"}>
                    <label>Secondary colour <LightTooltip title="General styling secondary colour" placement="right-start"
                                                          arrow><InfoIcon
                        color={"secondary"}/></LightTooltip></label>
                </Grid>
                <Grid item xs={12} sm={7} md={5}>
                    <ColorPicker
                        value={props.generalSettings.secondaryColor}
                        onChange={color => {
                            var newColor = '#'+color.hex;
                            if(typeof color === "string"){
                                newColor = color;
                            }
                            handleGeneralSettingsChange({...props.generalSettings, secondaryColor: newColor })
                        }}
                    />
                </Grid>
            </Grid>
            <Grid container className={"form-group"}>
                <Grid item xs={12} sm={4} md={3} className={"align-self-center"}>
                    <label>Background colour <LightTooltip title="General styling background colour"
                                                           placement="right-start" arrow><InfoIcon
                        color={"secondary"}/></LightTooltip></label>
                </Grid>
                <Grid item xs={12} sm={7} md={5}>
                    <ColorPicker
                        value={props.generalSettings.backgroundColor}
                        onChange={color => {
                            var newColor = '#'+color.hex;
                            if(typeof color === "string"){
                                newColor = color;
                            }
                            handleGeneralSettingsChange({...props.generalSettings, backgroundColor: newColor })
                        }}
                    />
                </Grid>
            </Grid>
        </div>
    </form>;
}

export default EditGeneralSettings;
