import React from "react";
import SecondaryBlock from "../components/SecondaryBlock";
import LinkButton from "../components/LinkButton";
import Card from "../components/Card";
import PrimaryBlock from "../components/PrimaryBlock";
import { Grid, Hidden, Box, Typography } from "@material-ui/core";
import Header from "../components/Header";
import Footer from "../components/Footer";

interface ProductValuePropositionProps {
    next: string;
}

const ProductValueProposition = (props: ProductValuePropositionProps) => {
    return <>
        <Header />

        <PrimaryBlock>
            <Grid container spacing={2}>
                <Grid item md={4} sm={12}>
                    <div className={"hero-image"}>
                        <img src="/images/hero-image-1.png" alt="" />
                    </div>
                </Grid>
                <Hidden smDown><Grid item md={1}></Grid></Hidden>
                <Grid item md={7} sm={12} className="justify-content-center align-self-center">
                    <h2>Track your spending with</h2>
                    <Typography color={"secondary"}><h1>Money Simple</h1></Typography>
                    <Box mb={3} className={"mt-10"}>
                        Money simple is brought to you in conjunction with illion's Open Data Solutions. Open Data Solutions proprietary technology retrieves data from leading financial institutions and is independently tested and audited by external security experts.
                    </Box>
                    <LinkButton targetlocation={props.next} variant={"contained"} color={"secondary"}>Get started</LinkButton>
                </Grid>
            </Grid>
        </PrimaryBlock>

        <SecondaryBlock header={"About Money Simple"}>
            <div className={"items"}>
                <Grid container spacing={2}>
                    <Grid item md={4} sm={12}>
                        <Card image="/images/icon-1.png">
                            See where your money goes, and identify strategies that can help make your money work harder for you.
                        </Card>
                    </Grid>
                    <Grid item md={4} sm={12}>
                        <Card image="/images/icon-2.png">
                            See where your money goes, and identify strategies that can help make your money work harder for you.
                        </Card>
                    </Grid>
                    <Grid item md={4} sm={12}>
                        <Card image="/images/icon-3.png">
                            See where your money goes, and identify strategies that can help make your money work harder for you.
                        </Card>
                    </Grid>
                </Grid>
            </div>
        </SecondaryBlock>

        <Footer />
    </>;
}

export default ProductValueProposition;