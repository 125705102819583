import React, {useCallback, useEffect, useState} from "react";
import AdminHeader from "../components/AdminHeader";
import AdminFooter from "../components/AdminFooter";
import {Redirect, Route, useHistory, useLocation} from "react-router-dom";
import AdminLogin from "./AdminLogin";
import {Switch as RouterSwitch} from "react-router";
import {Container} from "@material-ui/core";
import Configuration from "../models/Configuration";
import Configurations from "./Configurations";
import OpenBankingPlatformAPIInterface from "../../openbankingplatform/OpenBankingPlatformAPIInterface";

interface AdminState
{
    configurations?: Array<Configuration>;
    backgroundOperationInProgress: boolean;
    loggedIn: boolean;
}

interface AdminProps
{
    basePath: string;
    api: OpenBankingPlatformAPIInterface;
}

const Admin = (props: AdminProps) => {
    const [state, setState] = useState<AdminState>({
        backgroundOperationInProgress: false,
        loggedIn: true,
    });

    const location = useLocation();
    useEffect(() => {
        const hideTabBar = () => {
            setState(state => ({
                ...state,
                tabs: undefined,
            }))
        }
        if (!location.pathname.includes("configuration")) {
            hideTabBar();
        }
    }, [location.pathname])

    // TODO there must be a more uniform way to ensure useEffect dependencies stay constant, without redeclaring them all
    // eslint-disable-next-line
    const getPrincipalConfigurations = useCallback(props.api.getPrincipalConfigurations, []);

    useEffect(() => {
        (async () => {
            const loadedConfigurations = await getPrincipalConfigurations(0);

            setState((state) => ({
                ...state,
                configurations: loadedConfigurations,
            }));
        })();
    }, [getPrincipalConfigurations]);

    const handleUpdateConfiguration = (configurationId: number, newConfiguration: Configuration) => {
        if (!state.configurations) {
            return;
        }

        const newConfigurations = state.configurations.map((configuration) =>
            (
                (configuration.id === configurationId)
                    ? newConfiguration
                    : configuration
            )
        );

        setState({
            ...state,
            configurations: newConfigurations,
        })
    };

    const history = useHistory();
    const handleCreateConfiguration = (name: string, description: string) => {
        setState({
            ...state,
            backgroundOperationInProgress: true,
        });

        (async () => {
            const newConfiguration = await props.api.createConfiguration(0, name, description);
            
            if (!state.configurations) {
                return;
            }

            const newConfigurations = state.configurations.slice();
            //temporarily set new id for config and may be removed when real api implemented if id is wrong
            newConfiguration.id = newConfigurations.length;

            newConfigurations.push(newConfiguration);

            setState({
                ...state,
                configurations: newConfigurations,
                backgroundOperationInProgress: false,
            });

            const newId = newConfiguration.id;
            history.push(`${props.basePath}/configuration/open/${newId}`);
        })();
    };

    const handleLogin = () => {
        setState({
            ...state,
            loggedIn: true,
        })
    }

    const loginPath = props.basePath + '/login';

    return <>
        <AdminHeader
            showMenu
            basePath={props.basePath}
            configurations={state.configurations}
            showNavigation={location.pathname !== loginPath}
        />

        <Container className={"admin-content"}>
            {(state.backgroundOperationInProgress)
                ? <>Saving...</>
                : <RouterSwitch>
                    <Route path={loginPath}>
                        <AdminLogin 
                            onLogin={handleLogin}
                            basePath={props.basePath}
                        />
                    </Route>

                    <Route path={props.basePath + "/configuration"}>
                        <Configurations configurations={state.configurations}
                                        onUpdateConfiguration={handleUpdateConfiguration}
                                        onCreateConfiguration={handleCreateConfiguration}
                                        basePath={props.basePath}
                        />
                    </Route>

                    <Route path={props.basePath}>
                        {(state.loggedIn)
                            ? <Redirect to={props.basePath + "/configuration"}/>
                            : <Redirect to={props.basePath + "/login"}/>
                        }
                    </Route>

                    <Route>
                        <div>
                            <h3>Not found</h3>
                            <p>The page you're requesting was not found.</p>
                        </div>
                    </Route>
                </RouterSwitch>
            }
        </Container>

        <AdminFooter/>
    </>
}

export default Admin;