import React, { PropsWithChildren } from "react";
import { makeStyles } from "@material-ui/core";

export interface SwitchProps {
    checked: boolean,
    onChange?: (newCheckedState: boolean) => void,
    disabled?: boolean
}

const useStyles = makeStyles((theme) => ({
    checked: {
        backgroundColor: theme.palette.secondary.main
    }
}));

const Switch = (props: PropsWithChildren<SwitchProps>) => {
    const style = useStyles();

    return <div className="switch">
        <label className={"switch-content"}>
            {props.children}
            <input
                checked={props.checked}
                onChange={(event) => {
                    if (props.onChange) {
                        props.onChange(event.currentTarget.checked)
                    }
                }}
                disabled={props.disabled}
                className="switch-checkbox"
                type="checkbox"
            />
            <div className={`switch-background ${props.checked ? style.checked : ""}`}>
                <div className={`switch-button`} />
            </div>
        </label>
    </div>;
}

export default Switch;
