import React from "react";
import { useHistory } from "react-router-dom";

interface ConfigurationListItemProps {
    id: Number,
    name: string;
    description: string;
    basePath: string;
}

const DashboardConfigurationCard = (props: ConfigurationListItemProps) => {
    const history = useHistory();

    const handleViewEdit = () => {
        history.push(`${props.basePath}/configuration/open/${props.id}`);
    }

    return <div className={"configuration-item"}>
        <h6>{props.name}</h6>
        {props.description && <p>{props.description}</p>}
        <a href="/#" onClick={(e) => {e.preventDefault(); handleViewEdit();}} >View / Edit</a>
    </div>;
}

export default DashboardConfigurationCard;
