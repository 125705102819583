import HighlightBlock from "./HighlightBlock";
import SwitchWithLabel from "./SwitchWithLabel";
import React from "react";

export interface AdditionalUseChoiceModel {
    label: string,
    isSelected: boolean,
}

interface AdditionalUseChoicesProps {
    title?: string,
    choices: Array<AdditionalUseChoiceModel>,
    onChoiceChange?: (newChoice: boolean, index: number) => void,
}

const AdditionalUseChoices = (props: AdditionalUseChoicesProps) => {
    const handleChange = (checked: boolean, index: number) => {
        if (props.onChoiceChange) {
            props.onChoiceChange(checked, index);
        }
    }

    return <HighlightBlock className="additional-use-choices">
        {(props.title) ? <h5>{props.title}</h5> : ""}
        {props.choices.map((choice, index) =>
            <div className={"item"} key={index}>
                <label>
                    <SwitchWithLabel checked={choice.isSelected} onChange={checked => handleChange(checked, index)}>
                        <h6>{choice.label}</h6>
                    </SwitchWithLabel>
                </label>
            </div>
        )}
    </HighlightBlock>
}

export default AdditionalUseChoices;