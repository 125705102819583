import React, {useEffect, useState, useRef, MutableRefObject, ChangeEvent} from "react";
import {Box, Grid, Hidden, Button, FormControl} from "@material-ui/core";
import InfoIcon from '@material-ui/icons/Info';
import ButtonBlock from "../../components/ButtonBlock";
import Configuration from "../models/Configuration";
import SwitchWithLabel from "../../components/SwitchWithLabel";
import FileItem from "./FileItem";
import PreConsent from "../../models/PreConsent";
import EditableText from "./EditableText";
import LightTooltip from "./LightTooltip";

interface EditPreConsentSettingsProps
{
    configuration: Configuration,
    mode: "edit" | "view",
    onConfigurationUpdate?: (newConfiguration: Configuration) => void,
    onCancel?: () => void,
}

interface PreConsentSettingsState
{
    preConsentCopy: PreConsent;
}

const ConfigurationPreConsentSettings = (props: EditPreConsentSettingsProps) => {
    const pvpKeyVisualFileUploadInput = useRef() as MutableRefObject<HTMLInputElement>;
    const cdrSupportingImageFileUploadInput = useRef() as MutableRefObject<HTMLInputElement>;
    const [state, setState] = useState<PreConsentSettingsState>({
        preConsentCopy: {...props.configuration.preConsent},
    });

    const bgClass = (props.mode === "edit") ? "background-grey" : "";

    useEffect(() => {
        setState((state) => ({
            ...state,
            preConsentCopy: {...props.configuration.preConsent}
        }))
    }, [props.mode, props.configuration.preConsent]);

    const setPreConsentSettings = (newPreConsentSettings: PreConsent) => {
        setState({
            ...state,
            preConsentCopy: newPreConsentSettings,
        });
    }

    const handlepvpKeyVisualUploadClick = () => {
      pvpKeyVisualFileUploadInput.current.click();
    }

    const handlepvpKeyVisualUploadCapture = (event: ChangeEvent<HTMLInputElement>) => {
      var filename = "";
        if(event.target.files === null){
            filename = "No file found";
        }
        else{
            filename = event.target.files[0].name;
        }
        setPreConsentSettings({...preConsent, pvpKeyVisualFileName: filename})
    }

    const handlecdrSupportingImageUploadClick = () => {
      cdrSupportingImageFileUploadInput.current.click();
    }

    const handlecdrSupportingImageUploadCapture = (event: ChangeEvent<HTMLInputElement>) => {
      var filename = "";
        if(event.target.files === null){
            filename = "No file found";
        }
        else{
            filename = event.target.files[0].name;
        }
        setPreConsentSettings({...preConsent, cdrSupportingImageFileName: filename})
    }

    const handleSave = () => {
        const newConfiguration: Configuration = {
            ...props.configuration,
            preConsent: state.preConsentCopy,
        }

        props.onConfigurationUpdate && props.onConfigurationUpdate(newConfiguration);
    };

    const handleCancel = () => {
        props.onCancel && props.onCancel();
    }

    const preConsent = state.preConsentCopy;
    const editMode = (props.mode === "edit");
    const form =
        <form className={"admin-form pre-consent-form mt-50"} autoComplete={"off"}>
            <label>
                <SwitchWithLabel
                    checked={preConsent.inclPreConsentStage}
                    disabled={!editMode}
                    onChange={value => {
                        setPreConsentSettings({...preConsent, inclPreConsentStage: value})
                    }}>
                    <h5>Include Pre-Consent Stage</h5>
                </SwitchWithLabel>
            </label>

            {preConsent.inclPreConsentStage &&
            <>
              <div className={"mt-50"}>
                <Box mb={2}>
                  <h4>Product Value Proposition</h4>
                </Box>
                <Grid container className={"mt-30"}>
                  <Grid item xs={12} sm={4} md={3}>
                    <label>Key Visual <LightTooltip title="Key visual for product Value proposition" placement="right-start"
                                                    arrow><InfoIcon color={"secondary"}/></LightTooltip></label>
                  </Grid>
                  <Grid item xs={12} sm={7} md={5}>
                    <FormControl>
                      <SwitchWithLabel
                        checked={preConsent.inclKeyVisual}
                        disabled={!editMode}
                        onChange={value => {
                            setPreConsentSettings({...preConsent, inclKeyVisual: value})
                        }}>
                        <h5>Include</h5>
                      </SwitchWithLabel>
                    </FormControl>
                    <Grid item md={"auto"} className = {"grid-file-input"}>
                      <div>
                        <Box className={"details-box"}>
                          <FileItem type={"image"} name={preConsent.pvpKeyVisualFileName}/>
                        </Box>
                        <div className="date">Uploaded 20/10/2020</div>
                      </div>
                      <Box mt={2} className = {"input-file-box"}>
                          <input
                              color="primary"
                              accept="image/*"
                              type="file"
                              onChange={handlepvpKeyVisualUploadCapture}
                              id="pvp-keyvisual-upload"
                              style={{ display: 'none', }}
                              ref={pvpKeyVisualFileUploadInput}
                          />
                          <a href={"/#"} 
                              onClick={(e) => {e.preventDefault(); if(editMode) {handlepvpKeyVisualUploadClick();} }}
                          >
                              Upload new
                          </a>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container className={"mt-30"}>
                  <Grid item xs={12} sm={4} md={3}>
                    <label>Key Text <LightTooltip title="Key text for product Value proposition" placement="right-start"
                                                  arrow><InfoIcon color={"secondary"}/></LightTooltip></label>
                  </Grid>
                  <Grid item xs={12} sm={7} md={5}>
                    <FormControl>
                      <SwitchWithLabel
                        checked={preConsent.inclKeyText}
                        disabled={!editMode}
                        onChange={value => {
                            setPreConsentSettings({...preConsent, inclKeyText: value})
                        }}>
                        <h5>Include</h5>
                      </SwitchWithLabel>
                    </FormControl>
                    <Box className={`details-box ${bgClass}`}>
                      <Box>
                        <label>Main Heading <LightTooltip title="Main heading for key text" placement="right-start"
                                                          arrow><InfoIcon color={"secondary"}/></LightTooltip></label>
                        <EditableText
                          editMode={editMode}
                          name={"mainHeading"}
                          value={preConsent.keyTextHeading}
                          eventHandlers={{
                              onChange: (e: any) => {
                                  setPreConsentSettings({...preConsent, keyTextHeading: e.target.value})
                              }
                          }}
                        />
                      </Box>
                      <Box mt={4}>
                        <label>Main Text <LightTooltip title="Main text for key text" placement="right-start" arrow><InfoIcon
                          color={"secondary"}/></LightTooltip></label>
                        <EditableText
                          textArea
                          editMode={editMode}
                          name={"mainText"}
                          value={preConsent.keyText}
                          eventHandlers={{
                              onChange: (e: any) => {
                                  setPreConsentSettings({...preConsent, keyText: e.target.value})
                              }
                          }}
                        />
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
                <Grid container className={"mt-30"}>
                  <Grid item xs={12} sm={4} md={3}>
                    <label>Call to Action Text <LightTooltip title="Call to Action Text for product Value proposition" placement="right-start"
                                                             arrow><InfoIcon
                      color={"secondary"}/></LightTooltip></label>
                  </Grid>
                  <Grid item xs={12} sm={7} md={5} className={bgClass}>
                    <EditableText
                      editMode={editMode}
                      name={"ctaText"}
                      value={preConsent.ctaText}
                      eventHandlers={{
                          onChange: (e: any) => {
                              setPreConsentSettings({...preConsent, ctaText: e.target.value});
                          }
                      }}
                    />
                  </Grid>
                </Grid>
              </div>

              <div className={"mt-50"}>
                <Box mb={2}>
                  <h4>Consumer Data Right Value Proposition &amp; Branding</h4>
                </Box>
                <Grid container className={"mt-30"}>
                  <Grid item xs={12} sm={4} md={3}>
                    <label>Supporting Image <LightTooltip title="Consumer data right value proposition &amp; branding supporting image" placement="right-start"
                                                          arrow><InfoIcon color={"secondary"}/></LightTooltip></label>
                  </Grid>
                  <Grid item xs={12} sm={7} md={5}>
                    <FormControl>
                      <SwitchWithLabel 
                        checked={preConsent.includeCdrSupportingImage}
                        disabled={!editMode}
                        onChange={value => {
                          setPreConsentSettings({...preConsent, includeCdrSupportingImage: value})
                        }}
                      >
                        <h5>Include</h5>
                      </SwitchWithLabel>
                    </FormControl>
                    <Grid item md={"auto"} className = {"grid-file-input"}>
                      <div>
                        <Box className={"details-box"}>
                          <FileItem type={"image"} name={preConsent.cdrSupportingImageFileName}/>
                        </Box>
                        <div className="date">Uploaded 20/10/2020</div>
                      </div>
                      <Box mt={2} className = {"input-file-box"}>
                          <input
                              color="primary"
                              accept="image/*"
                              type="file"
                              onChange={handlecdrSupportingImageUploadCapture}
                              id="pvp-keyvisual-upload"
                              style={{ display: 'none', }}
                              ref={cdrSupportingImageFileUploadInput}
                          />
                          <a href={"/#"} 
                              onClick={(e) => {e.preventDefault(); if(editMode) {handlecdrSupportingImageUploadClick();} }}
                          >
                              Upload new
                          </a>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container className={"mt-30"}>
                  <Grid item xs={12} sm={4} md={3}>
                    <label>Key Text <LightTooltip title="Consumer data right value proposition &amp; branding key text" placement="right-start"
                                                  arrow><InfoIcon color={"secondary"}/></LightTooltip></label>
                  </Grid>
                  <Grid item xs={12} sm={7} md={5}>
                    <FormControl>
                      <SwitchWithLabel 
                        checked={preConsent.includeCdrKeyText}
                        disabled={!editMode}
                        onChange={value => {
                          setPreConsentSettings({...preConsent, includeCdrKeyText: value})
                        }}
                      >
                        <h5>Include</h5>
                      </SwitchWithLabel>
                    </FormControl>
                    <Box className={`details-box ${bgClass}`}>
                      <Box>
                        <label>Heading <LightTooltip title="Consumer data right value proposition &amp; branding key text heading" placement="right-start"
                                                     arrow><InfoIcon color={"secondary"}/></LightTooltip></label>
                        <EditableText
                          editMode={editMode}
                          name={"mainHeading"}
                          value={preConsent.cdrKeyTextHeading}
                          wrapper={"div"}
                          eventHandlers={{
                            onChange: (e: any) => {
                                setPreConsentSettings({...preConsent, cdrKeyTextHeading: e.target.value});
                            }
                          }}
                        />
                      </Box>
                      <Box mt={4}>
                        <label>Main Text <LightTooltip title="Consumer data right value proposition &amp; branding key text main text" placement="right-start" arrow><InfoIcon
                          color={"secondary"}/></LightTooltip></label>
                        <EditableText
                          textArea
                          editMode={editMode}
                          name={"mainHeading"}
                          rowsMin={8}
                          value={preConsent.cdrKeyTextMainText}
                          wrapper={"div"}
                          eventHandlers={{
                            onChange: (e: any) => {
                                setPreConsentSettings({...preConsent, cdrKeyTextMainText: e.target.value});
                            }
                          }}
                        />
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
                <Grid container className={"mt-30"}>
                  <Grid item xs={12} sm={4} md={3}>
                    <label>CDR Value Proposition &amp; Information <LightTooltip title="CDR Value Proposition &amp; Information"
                                                                                 placement="right-start" arrow><InfoIcon
                      color={"secondary"}/></LightTooltip></label>
                  </Grid>
                  <Grid item xs={12} sm={7} md={5}>
                    <FormControl>
                      <SwitchWithLabel 
                        checked={preConsent.includeCdrVpInformation}
                        disabled={!editMode}
                        onChange={value => {
                          setPreConsentSettings({...preConsent, includeCdrVpInformation: value})
                        }}
                      >
                        <h5>Include</h5>
                      </SwitchWithLabel>
                    </FormControl>
                    <Box className={`details-box ${bgClass}`}>
                      <Box>
                        <label>Heading <LightTooltip title="CDR Value Proposition &amp; Information heading" placement="right-start"
                                                     arrow><InfoIcon color={"secondary"}/></LightTooltip></label>
                        <EditableText
                          editMode={editMode}
                          name={"cdrInformationHeading"}
                          value={preConsent.cdrVpInformationHeading}
                          eventHandlers={{
                            onChange: (e: any) => {
                                setPreConsentSettings({...preConsent, cdrVpInformationHeading: e.target.value});
                            }
                          }}
                        />
                      </Box>
                      <Box mt={4}>
                        <label>Main Text <LightTooltip title="CDR Value Proposition &amp; Information main text" placement="right-start" arrow><InfoIcon
                          color={"secondary"}/></LightTooltip></label>
                        <EditableText
                          textArea
                          editMode={editMode}
                          name={"cdrInformationText"}
                          value={preConsent.cdrVpInformationMainText}
                          eventHandlers={{
                            onChange: (e: any) => {
                                setPreConsentSettings({...preConsent, cdrVpInformationMainText: e.target.value});
                            }
                          }}
                        />
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
                <Grid container className={"mt-30"}>
                  <Grid item xs={12} sm={4} md={3}>
                    <label>Link to further verification and info <LightTooltip title="Link to further verification and info for cdr"
                                                                               placement="right-start" arrow><InfoIcon
                      color={"secondary"}/></LightTooltip></label>
                  </Grid>
                  <Grid item xs={12} sm={7} md={5}>
                    <FormControl>
                      <SwitchWithLabel 
                        checked={preConsent.includeLinkToFurtherVerification}
                        disabled={!editMode}
                        onChange={value => {
                          setPreConsentSettings({...preConsent, includeLinkToFurtherVerification: value})
                        }}
                      >
                        <h5>Include</h5>
                      </SwitchWithLabel>
                    </FormControl>
                    <EditableText
                      editMode={editMode}
                      name={"cdrPolicyUrl"}
                      value={preConsent.linkToFurtherVerification}
                      eventHandlers={{
                        onChange: (e: any) => {
                            setPreConsentSettings({...preConsent, linkToFurtherVerification: e.target.value});
                        }
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container className={"mt-30"}>
                  <Grid item xs={12} sm={4} md={3}>
                    <label>Key CDR Features &amp; Information <LightTooltip title="Key CDR Features &amp; Information"
                                                                            placement="right-start" arrow><InfoIcon
                      color={"secondary"}/></LightTooltip></label>
                  </Grid>
                  <Grid item xs={12} sm={7} md={5}>
                    <FormControl>
                      <SwitchWithLabel 
                        checked={preConsent.includeKeyFeaturesAndInformation}
                        disabled={!editMode}
                        onChange={value => {
                          setPreConsentSettings({...preConsent, includeKeyFeaturesAndInformation: value})
                        }}
                      >
                        <h5>Include</h5>
                      </SwitchWithLabel>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container className={"mt-30"}>
                  <Grid item xs={12} sm={4} md={3}>
                    <label>Cancel Button <LightTooltip title="To include cancel button or not" placement="right-start" arrow><InfoIcon
                      color={"secondary"}/></LightTooltip></label>
                  </Grid>
                  <Grid item xs={12} sm={7} md={5}>
                    <FormControl>
                      <SwitchWithLabel 
                        checked={preConsent.includeCancelButtonAndPopUp}
                        disabled={!editMode}
                        onChange={value => {
                          setPreConsentSettings({...preConsent, includeCancelButtonAndPopUp: value})
                        }}
                      >
                        <h5>Include</h5>
                      </SwitchWithLabel>
                    </FormControl>
                    <Box className={`details-box ${bgClass}`}>
                      <Box>
                        <label>Modal Heading <LightTooltip title="Cancel modal heading" placement="right-start"
                                                           arrow><InfoIcon color={"secondary"}/></LightTooltip></label>
                        <EditableText
                          editMode={editMode}
                          name={"modalHeading"}
                          value={preConsent.cancelModalPopUpHeading}
                          eventHandlers={{
                            onChange: (e: any) => {
                                setPreConsentSettings({...preConsent, cancelModalPopUpHeading: e.target.value});
                            }
                          }}
                        />
                      </Box>
                      <Box mt={4}>
                        <label>Modal Text <LightTooltip title="Cancel modal text" placement="right-start"
                                                        arrow><InfoIcon color={"secondary"}/></LightTooltip></label>
                        <EditableText
                          textArea
                          editMode={editMode}
                          name={"modalText"}
                          rowsMin={8}
                          value={preConsent.cancelModalPopUpMainText}
                          eventHandlers={{
                            onChange: (e: any) => {
                                setPreConsentSettings({...preConsent, cancelModalPopUpMainText: e.target.value});
                            }
                          }}
                        />
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </div>
            </>
            }

            {(props.mode === 'edit') &&
            <Grid container className={"mt-20"}>
              <Hidden xsDown><Grid item xs={12} sm={4} md={3}></Grid></Hidden>
              <Grid item xs={12} sm={7} md={5}>
                <ButtonBlock className={"text-align-right"}>
                  <Button onClick={() => handleCancel()} variant={"outlined"} color={"secondary"}>Cancel</Button>
                  <Button onClick={() => handleSave()} variant={"contained"} color={"secondary"}>Save</Button>
                </ButtonBlock>
              </Grid>
            </Grid>
            }
        </form>;

    return <>
        <h2 className={"page-title"}>Pre-Consent Configuration</h2>
        <h5 className="text-large">Money Simple</h5>

        <Grid container className={"mt-30"}>
            <Grid item xs={12} sm={11} md={8}>
                The pre-consent stage consists of a general onboarding experience and takes place prior to the Consent
                Flow. Consumer trust is critical to CDR adoption. Trust SHOULD be built prior to an ADR requesting CDR
                data, and when this occurs depends on whether or not the consumer has a pre-existing relationship with
                the ADR.
            </Grid>
        </Grid>

        {form}
    </>;
}

export default ConfigurationPreConsentSettings;