import React from "react";
import {Link, useHistory} from 'react-router-dom';
import {Container, Menu, MenuItem, Button, Typography} from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import Configuration from "../models/Configuration";
import './../styles/Admin.scss';

interface AdminHeaderProps
{
    configurations?: Array<Configuration>;
    basePath: string;
    title?: string;
    showMenu?: boolean;
    showNavigation?: boolean;
}

const AdminHeader = (props: AdminHeaderProps) => {
    const history = useHistory();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        console.log("logged out");
    };

    return <>
        <header className="admin-header">
            <Container maxWidth={"lg"} className={"top-bar"}>
                <Link id="logo" to={props.basePath}><img src={"/images/logo-ods-white.png"} alt=""/></Link>
                {props.showMenu &&
                    <div className={"admin-menu-bar"}>
                        <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleMenuClick}>
                            Money Simple{anchorEl ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/>}
                        </Button>
                        <Menu
                          className={"admin-menu"}
                          anchorEl={anchorEl}
                          anchorOrigin={{vertical: "bottom", horizontal: "left"}}
                          open={Boolean(anchorEl)}
                          onClose={handleMenuClose}
                        >
                            {props.configurations && props.configurations.map((configuration, i) => (
                                <MenuItem key={i} onClick={() => {
                                    handleMenuClose();
                                    history.push(props.basePath + `/configuration/open/${configuration.id}`);
                                }}>{configuration.name}</MenuItem>
                            ))}
                            <MenuItem className={"border-top"} key={"create"} onClick={() => {
                                handleMenuClose();
                                history.push(props.basePath + "/configuration/create");
                            }}>
                                <Typography color={"secondary"}><AddCircleOutlineIcon/> Create new configuration</Typography>
                            </MenuItem>
                        </Menu>
                      <span>Hello Angus</span>
                      <Link to={props.basePath + "/login"} color={"#fff"} onClick={handleLogout}>Log out</Link>
                    </div>
                }
            </Container>
        </header>
        <div className={"navigation-container"}>
            {(props.showNavigation)
                ? <div className="text-secondary">
                    {props.title && <h2 className={"page-title"}>{props.title}</h2>}
                </div>
                : ""
            }
        </div>
    </>
}

export default AdminHeader;
