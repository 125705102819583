import React from "react";
import {Box, Grid} from "@material-ui/core";
import InfoIcon from '@material-ui/icons/Info';
import LightTooltip from "./LightTooltip";
import GeneralSettings from "../models/GeneralSettings";
import Configuration from "../models/Configuration";

interface ViewGeneralSettingsProps
{
    configuration: Configuration,
    generalSettings: GeneralSettings,
}

const ViewGeneralSettings = (props: ViewGeneralSettingsProps) => {
    return <>
        {props.generalSettings ?
            <>
                <div className={"mt-50"}>
                    <Box mb={2}>
                        <h4>Configuration Details</h4>
                    </Box>
                    <Grid container className={"form-group"}>
                        <Grid item xs={12} sm={4} md={3} className={"align-self-center"}>
                            <label>Name <LightTooltip title="Name of the configuration" placement="right-start"
                                                      arrow><InfoIcon color={"secondary"}/></LightTooltip></label>
                        </Grid>
                        <Grid item xs={12} sm={7} md={5}>
                            {props.configuration.name}
                        </Grid>
                    </Grid>
                    <Grid container className={"form-group"}>
                        <Grid item xs={12} sm={4} md={3} className={"align-self-center"}>
                            <label>Description <LightTooltip title="Description of the configuration"
                                                             placement="right-start" arrow><InfoIcon
                                color={"secondary"}/></LightTooltip></label>
                        </Grid>
                        <Grid item xs={12} sm={7} md={5}>
                            {[props.configuration.description]}
                        </Grid>
                    </Grid>
                </div>


                <div className={"mt-50"}>
                    <Box mb={2}>
                        <h4>Principal’s Details</h4>
                    </Box>
                    <Grid container className={"form-group"}>
                        <Grid item xs={12} sm={4} md={3} className={"align-self-center"}>
                            <label>Name <LightTooltip title="Principal’s name" placement="right-start"
                                                      arrow><InfoIcon color={"secondary"}/></LightTooltip></label>
                        </Grid>
                        <Grid item xs={12} sm={7} md={5}>
                            {props.generalSettings.principalName}
                        </Grid>
                    </Grid>
                    <Grid container className={"form-group"}>
                        <Grid item xs={12} sm={4} md={3} className={"align-self-center"}>
                            <label>Accreditation Number <LightTooltip title="Principal’s accreditation Number"
                                                                      placement="right-start" arrow><InfoIcon
                                color={"secondary"}/></LightTooltip></label>
                        </Grid>
                        <Grid item xs={12} sm={7} md={5}>
                            {props.generalSettings.accreditationNumber}
                        </Grid>
                    </Grid>
                    <Grid container className={"form-group"}>
                        <Grid item xs={12} sm={4} md={3} className={"align-self-center"}>
                            <label>ADR Name <LightTooltip title="Principal’s ADR Name" placement="right-start"
                                                          arrow><InfoIcon
                                color={"secondary"}/></LightTooltip></label>
                        </Grid>
                        <Grid item xs={12} sm={7} md={5}>
                            {props.generalSettings.adrName}
                        </Grid>
                    </Grid>
                </div>

                <div className={"mt-50"}>
                    <Box mb={2}>
                        <h4>Legal Details</h4>
                    </Box>
                    <Grid container className={"form-group"}>
                        <Grid item xs={12} sm={4} md={3} className={"align-self-center"}>
                            <label>CDR Policy URL <LightTooltip title="Consumer data right Policy URL"
                                                                placement="right-start" arrow><InfoIcon
                                color={"secondary"}/></LightTooltip></label>
                        </Grid>
                        <Grid item xs={12} sm={7} md={5}>
                            {props.generalSettings.cdrPolicyURL}
                        </Grid>
                    </Grid>
                    <Grid container className={"form-group"}>
                        <Grid item xs={12} sm={4} md={3} className={"align-self-center"}>
                            <label>CDR Policy PDF <LightTooltip title="Consumer data right Policy PDF"
                                                                placement="right-start" arrow><InfoIcon
                                color={"secondary"}/></LightTooltip></label>
                        </Grid>
                        <Grid item xs={12} sm={7} md={5}>
                            {props.generalSettings.cdrPolicyPDF}
                        </Grid>
                    </Grid>
                </div>

                <div className={"mt-50"}>
                    <Box mb={2}>
                        <h4>General Styling</h4>
                    </Box>
                    <Grid container className={"form-group"}>
                        <Grid item xs={12} sm={4} md={3} className={"align-self-center"}>
                            <label>Font family <LightTooltip title="General styling font family"
                                                             placement="right-start" arrow><InfoIcon
                                color={"secondary"}/></LightTooltip></label>
                        </Grid>
                        <Grid item xs={12} sm={7} md={5}>
                            {props.generalSettings.fontFamily}
                        </Grid>
                    </Grid>
                    <Grid container className={"form-group"}>
                        <Grid item xs={12} sm={4} md={3} className={"align-self-center"}>
                            <label>Text colour <LightTooltip title="General styling text colour"
                                                             placement="right-start" arrow><InfoIcon
                                color={"secondary"}/></LightTooltip></label>
                        </Grid>
                        <Grid item xs={12} sm={7} md={5}>
                            {props.generalSettings.textColor}
                        </Grid>
                    </Grid>
                    <Grid container className={"form-group"}>
                        <Grid item xs={12} sm={4} md={3} className={"align-self-center"}>
                            <label>Primary colour <LightTooltip title="General styling primary colour"
                                                                placement="right-start" arrow><InfoIcon
                                color={"secondary"}/></LightTooltip></label>
                        </Grid>
                        <Grid item xs={12} sm={7} md={5}>
                            {props.generalSettings.primaryColor}
                        </Grid>
                    </Grid>
                    <Grid container className={"form-group"}>
                        <Grid item xs={12} sm={4} md={3} className={"align-self-center"}>
                            <label>Secondary colour <LightTooltip title="General styling secondary colour"
                                                                  placement="right-start" arrow><InfoIcon
                                color={"secondary"}/></LightTooltip></label>
                        </Grid>
                        <Grid item xs={12} sm={7} md={5}>
                            {props.generalSettings.secondaryColor}
                        </Grid>
                    </Grid>
                    <Grid container className={"form-group"}>
                        <Grid item xs={12} sm={4} md={3} className={"align-self-center"}>
                            <label>Background colour <LightTooltip title="General styling background colour"
                                                                   placement="right-start" arrow><InfoIcon
                                color={"secondary"}/></LightTooltip></label>
                        </Grid>
                        <Grid item xs={12} sm={7} md={5}>
                            {props.generalSettings.backgroundColor}
                        </Grid>
                    </Grid>
                </div>
            </>
            : <h5 className="text-large">Loading...</h5>
        }
    </>;
}

export default ViewGeneralSettings;