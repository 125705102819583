import DataClusterOptionSet from "../models/DataClusterOptionSet";
import Consent from "../models/Consent";
import PreConsent from "../models/PreConsent";
import Configuration from "../admin/models/Configuration";
import OpenBankingPlatformAPIInterface from "./OpenBankingPlatformAPIInterface";
import DataCluster from "./models/DataCluster";

type Configurations = Array<Configuration>;

const mockDataClustersWithOptions: Array<DataClusterOptionSet> = [
    {
        clusterId: 0,
        clusterName: "Name and occupation",
        purposeInformation: "These details will let us build your profile.",
        clusterPermissionNames: [
            "Name", "Occupation"
        ],
        authorisationScopeId: "common:customer.basic:read",
        isEnabled: true,
        isSelected: false,
        isMandatory: true,
    },
    {
        clusterId: 1,
        clusterName: "Contact details",
        purposeInformation: "These details will let us build your profile.",
        clusterPermissionNames: [
            "Phone", "Email address", "Mail address", "Residential address",
        ],
        authorisationScopeId: "common:customer.detail:read",
        isEnabled: true,
        isSelected: false,
        isMandatory: false,
    },
    {
        clusterId: 2,
        clusterName: "Name, occupation, contact details",
        purposeInformation: "These details will let us build your profile.",
        clusterPermissionNames: [
            "Name", "Occupation", "Phone", "Email address", "Mail address", "Residential address"
        ],
        authorisationScopeId: "common:customer.detail:read",
        isEnabled: true,
        isSelected: false,
        isMandatory: false,
    },
    {
        clusterId: 3,
        clusterName: "Organisation profile and contact details",
        purposeInformation: "These details will let us build your profile.",
        clusterPermissionNames: [
            "Agent name and role",
            "Organisation name",
            "Organisation numbers (ABN or ACN)",
            "Charity status",
            "Establishment date",
            "Industry",
            "Organisation type",
            "Country of registration",
            "Organisation address",
            "Mail address",
            "Phone number",
        ],
        authorisationScopeId: "common:customer.basic:read",
        isEnabled: true,
        isSelected: false,
        isMandatory: false,
    },
    {
        clusterId: 4,
        clusterName: "Organisation contact details",
        purposeInformation: "These details will let us build your profile.",
        clusterPermissionNames: [
            "Organisation address",
            "Mail address",
            "Phone number",
        ],
        authorisationScopeId: "common:customer.detail:read",
        isEnabled: true,
        isSelected: false,
        isMandatory: false,
    },
    {
        clusterId: 5,
        clusterName: "Organisation profile",
        purposeInformation: "These details will let us build your profile.",
        clusterPermissionNames: [
            "Agent name and role",
            "Organisation name",
            "Organisation numbers (ABN or ACN)",
            "Charity status",
            "Establishment date",
            "Industry",
            "Organisation type",
            "Country of registration",
        ],
        authorisationScopeId: "common:customer.detail:read",
        isEnabled: true,
        isSelected: false,
        isMandatory: false,
    },
    {
        clusterId: 6,
        clusterName: "Account name, type and balance",
        purposeInformation: "These details will let us build your profile.",
        clusterPermissionNames: [
            "Name of account",
            "Type of account",
            "Account balance",
        ],
        authorisationScopeId: "bank:accounts.basic:read",
        isEnabled: true,
        isSelected: false,
        isMandatory: false,
    },
    {
        clusterId: 7,
        clusterName: "Account numbers and features",
        purposeInformation: "These details will let us build your profile.",
        clusterPermissionNames: [
            "Account number",
            "Interest rates",
            "Fees",
            "Discounts",
            "Account terms",
            "Account mail address",
        ],
        authorisationScopeId: "bank:accounts.detail:read",
        isEnabled: true,
        isSelected: false,
        isMandatory: false,
    },
    {
        clusterId: 8,
        clusterName: "Account balance and details",
        purposeInformation: "This data allows us to identify how much money you are spending.",
        clusterPermissionNames: [
            "Name of account",
            "Type of account",
            "Account balance",
            "Account number",
            "Interest rates",
            "Fees",
            "Discounts",
            "Account terms",
            "Account mail address",
        ],
        authorisationScopeId: "bank:accounts.detail:read",
        isEnabled: true,
        isSelected: false,
        isMandatory: false,
    },
    {
        clusterId: 9,
        clusterName: "Transaction details",
        purposeInformation: "Transaction data allows us to identify how much money you have been spending.",
        clusterPermissionNames: [
            "Incoming and outgoing transactions",
            "Amounts",
            "Dates",
            "Descriptions of transactions",
            "Who you have sent money to and received money from; (e.g. their name)",
        ],
        authorisationScopeId: "bank:transactions:read",
        isEnabled: true,
        isSelected: false,
        isMandatory: false,
    },
    {
        clusterId: 10,
        clusterName: "Direct debits and scheduled payments",
        purposeInformation: "Transaction data allows us to identify how much money you have been spending.",
        clusterPermissionNames: [
            "Direct debits",
            "Scheduled payments",
        ],
        authorisationScopeId: "bank:regular_payments:read",
        isEnabled: true,
        isSelected: false,
        isMandatory: false,
    },
    {
        clusterId: 11,
        clusterName: "Saved payees",
        purposeInformation: "These details will let us build your profile.",
        clusterPermissionNames: [
            "Names and details of accounts you have saved (e.g. their BSB and Account Number, BPay CRN and Biller code, or NPP PayID)",
        ],
        authorisationScopeId: "bank:payees:read",
        isEnabled: true,
        isSelected: false,
        isMandatory: false,
    },
];

const mockConsent: Consent = {
    activeConsentDurationText: "We need to collect and the below information for 12 months. We will only use it to help you manage your budget for this financial year.",
    inclDataClusterPermissionText: true,
    dataClustersWithOptions: mockDataClustersWithOptions.slice(),
    includeAdditionalUsesOfdata: true,
	includeFrequencyOfDataCollectionText: true,
	frequencyOfDataCollectionTextMainHeading: "How often we’ll access your data",
	frequencyOfDataCollectionTextMainText: "We will do this every time you log into Money Simple. This will be ongoing for the next <selected time period>.",
	includeDataDeletionStandardText: true,
	includeReplaceAnExistingConsentText: true,
}

const mockPreConsent: PreConsent = {
    inclPreConsentStage: true,
    inclKeyVisual: true,
    inclKeyText: true,
    keyTextHeading: "Track your spending with Money Simple",
    keyText: "Money Simple is brought to you in conjunction with illion’s Open Data Solutions. Open Data Solutions proprietary technology retrieves data from leading financial institutions and is independently tested and audited by external security experts.",
    ctaText: "Get started",
    pvpKeyVisualFileName: "PVP Visual File image",
    cdrSupportingImageFileName: "CDR Supporting image",
    includeCdrSupportingImage: true,
	includeCdrKeyText: true,
	cdrKeyTextHeading: "Please provide your information",
	cdrKeyTextMainText: "To see where your money goes, and identify strategies for your personal financial situation, we need some information from you. We take your security very seriously. The credentials you provide are used solely to retrieve data on your behalf. Your credentials are stored by illion Open Data Solutions with bank level 256-bit encryption, secured by 2048-bit keys.",
	includeCdrVpInformation: true,
	cdrVpInformationHeading: "Share data with the Consumer Data Right",
	cdrVpInformationMainText: "You can connect us with your bank(s) via the Consumer Data Right. This option is quick, accurate and can save you time by providing us with correct information.",
    includeLinkToFurtherVerification: true,
    linkToFurtherVerification: "https://www.illion.com.au/about-us/cdr-policy",
	includeKeyFeaturesAndInformation: true,
	includeCancelButtonAndPopUp: true,
	cancelModalPopUpHeading: "Cancel Process?",
	cancelModalPopUpMainText: "By not sharing your bank data, we will not be able to automatically collect this information. However, you can still provide this information manually when setting up Money Simple. Are you sure you would like to cancel this process?",
}

const mockConfigurations: Array<Configuration> = [
    {
        id: 0,
        name: "Configuration #1",
        description: "Description of demo configuration one.",
        generalSettings: {
            principalName: "Money Simple",
            accreditationNumber: "123456789",
            adrName: "ADR name One",
            cdrPolicyPDF: "CDR policy pdf file holder",
            fontFamily: "Nunito Sans",
            textColor: "#404040",
            primaryColor: "#8C2980",
            secondaryColor: "#FF9843",
            backgroundColor: "#FFFFFF",
        },
        preConsent: { ...mockPreConsent },
        consent: { ...mockConsent },
    },
    {
        id: 1,
        name: "Configuration #2",
        description: "Description of demo configuration two.",
        generalSettings: {
            principalName: "Money Simple Two",
            accreditationNumber: "ABCDE12345",
            adrName: "ADR name Two",
            cdrPolicyPDF: "CDR policy pdf file holder",
            fontFamily: "Nunito Sans",
            textColor: "#404040",
            primaryColor: "#8C2980",
            secondaryColor: "#FF9843",
            backgroundColor: "#FFFFFF",
        },
        preConsent: {
            ...mockPreConsent,
            inclPreConsentStage: false,
        },
        consent: mockConsent,
    }
];


export default class OpenBankingPlatformMockApi implements OpenBankingPlatformAPIInterface
{
    constructor() {
        this.getDataClusters = this.getDataClusters.bind(this);
        this.updateConfiguration = this.updateConfiguration.bind(this);
        this.createConfiguration = this.createConfiguration.bind(this);
        this.getPrincipalConfigurations = this.getPrincipalConfigurations.bind(this);
        this.getConfiguration = this.getConfiguration.bind(this);

        this.newConfigurationId = 2;
        this.configurations = mockConfigurations.slice();
    }

    private newConfigurationId: number;
    private configurations: Array<Configuration>;

    private async doSimulatedRequest<T>(responseData: T): Promise<T>
    {
        const timeout = (ms: number) => {
            return new Promise(resolve => setTimeout(resolve, ms));
        }

        await timeout(1000);

        return responseData;
    }

    public async getDataClusters(): Promise<Array<DataCluster>> {
        return this.doSimulatedRequest<Array<DataCluster>>([]);
    }

    private getConfigurationById(configurationId: number) {
        return this.configurations.find((configuration) => (configuration.id === configurationId));
    }

    private getConfigurationIndexById(configurationId: number) {
        return this.configurations.findIndex((configuration) => (configuration.id === configurationId));
    }

    // TODO this should be replaced with call to getDataClusters and adding options outside the call
    public async getDataClustersWithOptions(): Promise<Array<DataClusterOptionSet>>
    {
        return this.doSimulatedRequest<Array<DataClusterOptionSet>>(
            mockDataClustersWithOptions.slice(),
        );
    }

    public async getConfiguration(configurationId: number): Promise<Configuration>
    {
        const configuration = this.getConfigurationById(configurationId);

        if (!configuration) {
            throw new Error("Requested configuration does not exist");
        }

        return this.doSimulatedRequest<Configuration>(configuration);
    }

    public async getPrincipalConfigurations(principalId: number): Promise<Array<Configuration>>
    {
        if (principalId === 0) {
            return this.doSimulatedRequest<Configurations>(this.configurations.slice());
        } else {
            return this.doSimulatedRequest<Configurations>([
                this.configurations[0],
            ]);
        }
    }

    public async updateConfiguration(principalId: number, newConfiguration: Configuration): Promise<Configuration>
    {
        const configurationIndex = this.getConfigurationIndexById(newConfiguration.id);

        if (!configurationIndex) {
            throw new Error("Requested configuration does not exist");
        }

        this.configurations[configurationIndex] = newConfiguration;
        return this.doSimulatedRequest<Configuration>(this.configurations[configurationIndex]);
    }

    public async createConfiguration(principalId: number, name: string, description: string): Promise<Configuration>
    {
        const newId = this.newConfigurationId;
        this.newConfigurationId++;

        const newConfiguration: Configuration = {
            id: newId,
            name: name,
            description: description,
            generalSettings: {
                principalName: "Money Simple Two",
                accreditationNumber: "ABCDE12345",
                adrName: "ADR name Two",
                cdrPolicyURL: "CDR policy url",
                cdrPolicyPDF: "CDR policy pdf file holder",
                fontFamily: "Nunito Sans",
                textColor: "#404040",
                primaryColor: "#8C2980",
                secondaryColor: "#FF9843",
                backgroundColor: "#FFFFFF",
            },
            preConsent: {
                ...mockPreConsent,
                inclPreConsentStage: false,
            },
            consent: { ...mockConsent }
        }

        this.configurations.push(newConfiguration);

        return this.doSimulatedRequest<Configuration>(newConfiguration)
    }

}
